<template>
  <div class="pa-6 full-height">
    <RandomizeWhitelistDialog
      :state="randomizeWhitelistDialog"
      :poolId="poolId"
      @closeDialog="randomizeWhitelistDialog = false"
    />
    <ImportWhitelistDialog
      :state="importWhitelistDialog"
      @doneImport="reset()"
      @closeDialog="importWhitelistDialog = false"
    />
    <CheckKYCDialog
      :state="checkKYCDialog"
      :poolId="poolId"
      @doneCheck="reset()"
      @closeDialog="checkKYCDialog = false"
    />
    <div class="d-flex align-center">
      <div class="section-title">{{ this.fixedPool.name }}</div>
      <v-spacer></v-spacer>
      <!-- <v-btn class="text-none px-5 py-5 mr-4" color="primary" @click="randomizeWhitelistDialog = true" outlined rounded
        ><v-icon class="mr-2">mdi-file-question</v-icon> Randomize Whitelist</v-btn
      > -->
      <!-- <v-btn class="text-none px-5 py-5 elevation-0 mr-4" color="primary" @click="checkKYCDialog = true" rounded
        >Check All KYC</v-btn
      > -->
      <download-csv :data="exportWhitelistCSV" :name="fileName">
        <v-btn class="text-none px-5 py-5 mr-4" color="primary" :disabled="isLoading" outlined rounded
          ><v-icon class="mr-2">mdi-file-excel</v-icon> Export CSV for Contract</v-btn
        >
      </download-csv>
      <vue-excel-xlsx :data="exportWhitelist" :columns="originalColumns" :filename="fileName" :sheetname="sheetName">
        <v-btn class="text-none px-5 py-5 mr-4" color="primary" :disabled="!isLoadReferenceComplete" outlined rounded
          ><v-icon class="mr-2">mdi-file-excel</v-icon> Export XLSX for Owner</v-btn
        >
      </vue-excel-xlsx>
      <v-btn
        class="text-none px-5 py-5 elevation-0"
        color="primary"
        :disabled="isLoading"
        @click="importWhitelistDialog = true"
        rounded
        ><v-icon class="mr-2">mdi-file-excel</v-icon> Import Whitelist</v-btn
      >
    </div>
    <!-- <v-card
      class="
        primary-border
        elevation-0
        d-flex
        flex-column
        align-center
        justify-center
        fetching-data-card
        mx-auto
        pa-6
        mt-4
      "
      v-if="!isFetchAllWhitelistDone"
    >
      <div class="d-flex align-center">
        <v-progress-circular :size="25" :width="5" color="primary" indeterminate></v-progress-circular>
        <div class="ml-2 font-weight-bold">Fetching all whitelist data</div>
      </div>
      <div class="content-text text-center mt-2">
        You can use "Export CSV for Contract" and "Export XLSX for Owner" only after fetching all data is done
      </div>
    </v-card> -->
    <div class="d-flex align-center justify-space-around pa-6 primary-border border-radius-16 mt-6 text-center">
      <div class="mr-6">
        <div class="field-title mb-1">Total registed accounts</div>
        <div class="field-number text-center">{{ this.totalRegisteredCount }}</div>
      </div>
      <div class="mr-6">
        <div class="field-title mb-1">Whitelisted accounts</div>
        <div class="field-number text-center">{{ this.whitelistedCount }}</div>
      </div>
      <div class="mr-6">
        <div class="field-title mb-1">Drone/Queen Bee accounts</div>
        <div class="field-number text-center">{{ this.highTierCount }}</div>
      </div>
      <div class="mr-6">
        <div class="field-title mb-1">Not whitelisted accounts</div>
        <div class="field-number text-center">{{ this.notWhitelistCount }}</div>
      </div>
      <div class="mr-6">
        <div class="field-title mb-1">Filled KYC accounts</div>
        <div class="field-number text-center">{{ this.filledKYCCount }}</div>
      </div>
      <div class="mr-6">
        <div class="field-title mb-1">Used referral accounts</div>
        <div class="field-number text-center">{{ this.usedReferralCount }}</div>
      </div>
    </div>
    <div class="pa-6 primary-border border-radius-16 mt-6">
      <v-row>
        <v-col cols="12" md="10" class="d-flex align-center">
          <v-text-field
            :disabled="isLoading"
            class="mr-4"
            v-model="walletAddress"
            placeholder="Wallet Address"
            dense
            outlined
            hide-details
          ></v-text-field>
          <v-select
            :items="searchTiers"
            :disabled="isLoading"
            v-model="searchTier"
            placeholder="Tier"
            class="mr-4"
            item-text="title"
            item-value="value"
            style="max-width: 240px"
            clearable
            outlined
            dense
            hide-details
          ></v-select>
          <div class="d-flex">
            <v-checkbox
              class="mt-0 mr-4"
              label="Whitelisted"
              v-model="isWhitelisted"
              :disabled="isLoading"
              hide-details
            ></v-checkbox>
            <v-checkbox
              class="mt-0"
              label="Not in Whitelist"
              v-model="notWhitelist"
              :disabled="isLoading"
              hide-details
            ></v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-end">
          <v-spacer></v-spacer>
          <!-- <v-btn class="text-none elevation-0 mt-1" color="primary" @click="search">Search</v-btn> -->
        </v-col>
      </v-row>
    </div>
    <v-card class="mt-6 border-radius-16 elevation-0" style="overflow: hidden">
      <div class="d-flex align-center justify-space-between py-4 px-2">
        <div class="font-weight-bold pl-4">Whitelist</div>
        <v-select
          :items="sortOptions"
          v-model="sortBy"
          class="mr-4"
          placeholder="Sort by"
          item-text="title"
          item-value="value"
          style="max-width: 280px"
          :disabled="isLoading"
          clearable
          outlined
          dense
          hide-details
        ></v-select>
      </div>
      <v-data-table :headers="headers" :items="slicedApplies" :loading="isLoading" hide-default-footer>
        <template v-slot:[`footer`]="{}">
          <div class="py-4">
            <v-pagination v-model="page" :length="totalPage" total-visible="8" circle> </v-pagination>
          </div>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id || '' }}
        </template>
        <template v-slot:[`item.walletAddress`]="{ item }">
          {{ item | getWalletAddress | truncateAddress(10, 6) }}
        </template>
        <template v-slot:[`item.tier`]="{ item }">
          {{ item | getTierType }}
        </template>
        <template v-slot:[`item.telegramState`]="{ item }">
          <v-icon color="success" v-if="item.tasks && item.tasks.telegram && item.tasks.telegram.isDone"
            >mdi-check-circle</v-icon
          >
          <v-icon color="error" v-else>mdi-close-circle</v-icon>
        </template>
        <template v-slot:[`item.twitterState`]="{ item }">
          <v-icon color="success" v-if="item.tasks && item.tasks.twitter && item.tasks.twitter.isDone"
            >mdi-check-circle</v-icon
          >
          <v-icon color="error" v-else>mdi-close-circle</v-icon>
        </template>
        <template v-slot:[`item.referenceCount`]="{ item }">
          <div v-if="item.isLoadingReference">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <div v-else>{{ item.referenceCount }}</div>
        </template>
        <template v-slot:[`item.totalTicket`]="{ item }">
          {{ item | getTotalTicket }}
        </template>
        <template v-slot:[`item.isKycFilled`]="{ item }">
          <v-icon
            color="success"
            v-if="item.investor && item.investor.status && item.investor.status === 'kyc-verified'"
            >mdi-check-circle</v-icon
          >
          <v-icon color="error" v-else>mdi-close-circle</v-icon>
        </template>
        <template v-slot:[`item.isWhitelisted`]="{ item }">
          <div class="d-flex justify-center">
            <v-switch
              v-model="item.isWhitelisted"
              @change="onToggleWhitelist(item)"
              :disabled="item.tier > 2"
              color="success"
              inset
              dense
            ></v-switch>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import ImportWhitelistDialog from '@/components/excel/ImportWhitelistDialog.vue'
import CheckKYCDialog from '@/components/excel/CheckKYCDialog.vue'
import RandomizeWhitelistDialog from '@/components/excel/RandomizeWhitelistDialog.vue'
import moment from 'moment'
import { get } from 'lodash'

const originalHeaders = [
  {
    text: 'Id',
    align: 'start',
    sortable: false,
    value: 'id',
  },
  {
    text: 'Wallet Address',
    align: 'start',
    sortable: false,
    value: 'walletAddress',
  },
  {
    text: 'Tier',
    align: 'start',
    sortable: false,
    value: 'tier',
  },
  {
    text: 'Telegram State',
    align: 'center',
    sortable: true,
    value: 'telegramState',
  },
  {
    text: 'Twitter State',
    align: 'center',
    sortable: false,
    value: 'twitterState',
  },
  {
    text: 'Social Ticket',
    align: 'center',
    sortable: false,
    value: 'ticket',
  },
  {
    text: 'Reference Count',
    align: 'center',
    sortable: false,
    value: 'referenceCount',
  },
  {
    text: 'Total Ticket',
    align: 'center',
    sortable: false,
    value: 'totalTicket',
  },
  {
    text: 'Is Whitelisted',
    align: 'center',
    sortable: false,
    value: 'isWhitelisted',
  },
  {
    text: 'Is FYC Filled',
    align: 'center',
    sortable: false,
    value: 'isKycFilled',
  },
]

export default {
  components: {
    ImportWhitelistDialog,
    RandomizeWhitelistDialog,
    CheckKYCDialog,
  },
  data() {
    return {
      poolId: '',
      headers: originalHeaders,
      page: 1,
      itemsPerPage: 10,
      sheetName: '',
      fileName: '',

      importWhitelistDialog: false,
      checkKYCDialog: false,
      randomizeWhitelistDialog: false,

      sortBy: '',
      searchTier: '',
      walletAddress: '',
      applies: [],
      isWhitelisted: false,
      notWhitelist: false,
      sortOptions: [
        {
          title: 'Social Ticket ascending',
          value: 'ticket:asc',
        },
        {
          title: 'Social Ticket descending',
          value: 'ticket:desc',
        },
      ],
      searchTiers: [
        {
          title: 'Social',
          value: 0,
        },
        {
          title: 'Mini Bee',
          value: 1,
        },
        {
          title: 'Worker Bee',
          value: 2,
        },
        {
          title: 'Drone Bee',
          value: 3,
        },
        {
          title: 'Queen Bee',
          value: 4,
        },
      ],
      originalColumns: [
        {
          label: 'Id',
          field: 'id',
        },
        {
          label: 'Wallet Address',
          field: 'walletAddress',
        },
        {
          label: 'Tier Type',
          field: 'tier',
          dataFormat: this.exportTierType,
        },
        {
          label: 'Register Date',
          field: 'createdAt',
          dataFormat: (value) => {
            return moment(value).format('DD/MM/YYYY HH:mm:ss')
          },
        },
        {
          label: 'Telegram State',
          field: 'tasks',
          dataFormat: this.exportTelegramState,
        },
        {
          label: 'Twitter State',
          field: 'tasks',
          dataFormat: this.exportTwitterState,
        },
        {
          label: 'Social Ticket',
          field: 'ticket',
        },
        {
          label: 'Referral Id',
          field: 'reference',
        },
        {
          label: 'Referral Count',
          field: 'referenceCount',
        },
        {
          label: 'Total Ticket',
          field: 'totalTicket',
        },
        {
          label: 'Is KYC Filled',
          field: 'investor',
          dataFormat: this.exportKycFilled,
        },
        {
          label: 'Is Whitelisted',
          field: 'status',
          dataFormat: this.exportWhitelisted,
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['chainId', 'account']),
    ...mapState('fixedPools', ['fixedPool']),
    ...mapState('user', ['stakeUsers']),
    ...mapGetters('apply', ['allApplies', 'isLoading', 'isLoadReferenceComplete']),
    exportWhitelist() {
      if (!this.allAppliesWithTier || this.allAppliesWithTier.length === 0) {
        return []
      }
      return this.allAppliesWithTier.map((apply) => {
        return {
          ...apply,
          pool: this.fixedPool.id,
        }
      })
    },
    exportWhitelistCSV() {
      if (!this.allAppliesWithTier || this.allAppliesWithTier.length === 0) {
        return []
      }
      const applies = this.allAppliesWithTier
        .filter((apply) => {
          if (
            !apply.status ||
            apply.status !== 'whitelisted' ||
            !apply.investor ||
            apply.investor.status !== 'kyc-verified'
          )
            return false
          const lastUpdateState = get(apply, 'investor.data.lastUpdateState', null)
          if (lastUpdateState) {
            const kycEndDate = get(this.fixedPool, 'data.whitelistConfig.kycEndDate', null)
            return moment.unix(+lastUpdateState / 1000).isBefore(moment.unix(+kycEndDate / 1000))
          }
          return true
        })
        .map((apply) => {
          return {
            walletAddress: apply.walletAddress,
            tier: apply.tier,
          }
        })
      return applies
    },
    allAppliesWithTier() {
      return this.allApplies.map((apply) => {
        const walletAddress = apply.walletAddress
        let tier = 0
        const isSolanaNetwork =
          apply.chainId && (+apply.chainId === 101 || +apply.chainId === 103 || +apply.chainId === 102)
        if (isSolanaNetwork)
          tier = walletAddress && this.stakeUsers[walletAddress] ? this.stakeUsers[walletAddress].tier : 0
        else if (apply.tierUser && apply.tierUser.username) {
          const tierAddress = apply.tierUser.username
          tier = tierAddress && this.stakeUsers[tierAddress] ? this.stakeUsers[tierAddress].tier : 0
        }
        const isWhitelisted = tier > 2 || apply.status === 'whitelisted'
        let ticket = tier === 2 ? 10 : tier === 1 ? 5 : 0
        if (apply.tasks && apply.tasks.telegram && apply.tasks.telegram.isDone) ticket += 1
        if (apply.tasks && apply.tasks.twitter && apply.tasks.twitter.isDone) ticket += 3
        let totalTicket = apply.referenceCount + ticket
        return { ...apply, tier, isWhitelisted, ticket, totalTicket }
      })
    },
    filteredApplies() {
      return this.allAppliesWithTier.filter((apply) => {
        if (this.walletAddress && !apply.walletAddress.includes(this.walletAddress)) return false
        if (this.searchTier && apply.tier !== this.searchTier) return false
        if (this.isWhitelisted && !this.notWhitelist && apply.status !== 'whitelisted') return false
        if (!this.isWhitelisted && this.notWhitelist && apply.status === 'whitelisted') return false
        return true
      })
    },
    slicedApplies() {
      return this.filteredApplies.slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage)
    },
    totalPage() {
      if (!this.allApplies || this.allApplies.length === 0) return 1
      return Math.ceil(this.allApplies.length / this.itemsPerPage)
    },
    isFetchAllWhitelistDone() {
      return this.allApplies.length > 0
    },
    totalRegisteredCount() {
      return this.filteredApplies.length
    },
    whitelistedCount() {
      return this.filteredApplies.filter((apply) => apply.status && apply.status === 'whitelisted').length
    },
    notWhitelistCount() {
      return this.filteredApplies.filter((apply) => !apply.status || apply.status !== 'whitelisted').length
    },
    highTierCount() {
      return this.filteredApplies.filter((apply) => apply.tier && apply.tier > 2).length
    },
    filledKYCCount() {
      return this.filteredApplies.filter(
        (apply) => apply.investor && apply.investor.status && apply.investor.status === 'kyc-verified'
      ).length
    },
    usedReferralCount() {
      return this.filteredApplies.filter((apply) => apply.referralUser).length
    },
  },
  filters: {
    getWalletAddress(item) {
      if (!item || !item.walletAddress) return ''
      return item.walletAddress
    },
    getTotalTicket(item) {
      return item.ticket + item.referenceCount
    },
    getTierType: (item) => {
      switch (item.tier) {
        case 0:
          return 'Social'
        case 1:
          return 'Mini Bee'
        case 2:
          return 'Worker Bee'
        case 3:
          return 'Drone Bee'
        case 4:
          return 'Queen Bee'
        default:
          return 'Social'
      }
    },
  },
  methods: {
    ...mapActions('fixedPools', ['fetchFixedPool']),
    ...mapActions('user', ['fetchStakeUsers']),
    ...mapActions('apply', ['fetchApplies', 'updateApplies', 'fetchAllApplies']),
    exportKycFilled(value) {
      if (value && value.status === 'kyc-verified') return 'Yes'
      return 'No'
    },
    exportWhitelisted(value) {
      if (value && value === 'whitelisted') return 'Yes'
      return 'No'
    },
    exportBooleanColumn(value) {
      if (value) return 'Yes'
      return 'No'
    },
    exportTwitterState(value) {
      if (value && value.twitter && value.twitter.isDone) return 'Yes'
      return 'No'
    },
    exportTelegramState(value) {
      if (value && value.telegram && value.telegram.isDone) return 'Yes'
      return 'No'
    },
    exportTierType(value) {
      switch (value) {
        case 0:
          return 'Social'
        case 1:
          return 'Mini Bee'
        case 2:
          return 'Worker Bee'
        case 3:
          return 'Drone Bee'
        case 4:
          return 'Queen Bee'
        default:
          return 'Social'
      }
    },
    async search() {
      this.$loading.active = true
      this.page = 1
      let query = {}
      if (this.walletAddress) query['walletAddress'] = this.walletAddress.toLowerCase()
      if (this.isWhitelisted && !this.notWhitelist) query.status = 'whitelisted'
      else if (!this.isWhitelisted && this.notWhitelist) query.status_ne = 'whitelisted'
      if (this.sortBy) query['_sort'] = this.sortBy
      await this.fetchApplies({
        pool: this.poolId,
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        ...query,
      })
      this.$loading.active = false
    },
    async onToggleWhitelist(item) {
      this.$dialog.confirm({
        title: `Add or remove from Whitelist`,
        text: `Are you sure you want to ${item.isWhitelisted ? 'Add' : 'Remove'} <b>${item.user.walletAddress}</b> ${
          item.isWhitelisted ? 'to' : 'from'
        } <b>${this.fixedPool.name}'s Whitelist</b>`,
        okText: 'Yes',
        cancelText: 'Cancel',
        done: async () => {
          try {
            this.$loading.active = true
            await this.updateWhitelist({
              id: item.id,
              status: item.isWhitelisted ? 'whitelisted' : item.status,
            })
            await this.fetchApplies({
              pool: this.poolId,
              page: this.page,
              itemsPerPage: this.itemsPerPage,
            })
          } catch (error) {
            this.$alert.error(error)
          } finally {
            this.$loading.active = false
          }
        },
        cancel: () => {
          item.status = !item.isWhitelisted ? 'whitelisted' : item.status
        },
      })
    },
    async fetchData() {
      await this.fetchFixedPool({
        chainId: this.chainId,
        id: this.poolId,
      })
      await this.fetchApplies({
        pool: this.poolId,
        page: this.page,
        itemsPerPage: this.itemsPerPage,
      })
    },
    async reset() {
      this.$loading.active = true
      await this.fetchData()
      this.isWhitelisted = false
      this.notWhitelist = false
      this.walletAddress = ''
      this.sortBy = ''
      this.$loading.active = false
    },
  },
  async created() {
    this.$loading.active = true
    this.poolId = this.$route.params.id
    if (!this.$route.params.id) {
      this.$alert.error('Wrong pool id!')
      this.$router.push('/fixedPools')
    }
    await this.fetchFixedPool({
      chainId: this.chainId,
      id: this.poolId,
    })
    if (!this.fixedPool) {
      this.$alert.error('Wrong pool id!')
      this.$router.push('/fixedPools')
    }
    await this.fetchStakeUsers()
    this.$loading.active = false
    this.fetchAllApplies({
      pool: this.poolId,
    })
    // this.applies = this.allAppliesWithTier()
    this.fileName = `${this.fixedPool.name}-kyc-filled.csv`
    this.sheetName = `whitelist`
  },
}
</script>

<style scoped>
.fetching-data-card {
  max-width: 640px;
  border-radius: 24px !important;
  background: var(--v-primary-lighten4) !important;
}
</style>