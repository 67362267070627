import Vue from 'vue'
import App from './App.vue'
import PluginHelper from '@/helpers/PluginHelper'
import utils from '@/plugins/utils'
import alert from '@/plugins/alert'
import dialog from '@/plugins/dialog'
import { inputRules } from '@/plugins/rule'
import { vueFilterRegister } from '@/plugins/filters'
import { componentRegister } from './plugins/componentRegister'
import loading from '@/plugins/loading'
import router from './router'
import moment from 'moment'
import store from './store'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'
import DatetimePicker from 'vuetify-datetime-picker'
import VueExcelXlsx from 'vue-excel-xlsx'
import JsonCSV from 'vue-json-csv'
Vue.config.productionTip = false

//app config
componentRegister()

Vue.component('downloadCsv', JsonCSV)
Vue.use(
  PluginHelper.create({
    $utils: utils,
    $loading: loading,
    $baseUrl: process.env.VUE_APP_API_ENDPOINT,
    $rules: inputRules,
    $dialog: dialog,
    $alert: alert,
    $moment: moment,
  })
)
Vue.use(DatetimePicker)
Vue.use(VueExcelXlsx)
vueFilterRegister()
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
