export default {
  alertData: {
    show: false,
    message: '',
    type: 'error',
  },
  close() {
    Object.assign(this.alertData, { show: false, message: '' })
  },
  show(type, message) {
    Object.assign(this.alertData, { show: true, type, message })
  },
  error(msg) {
    this.close()
    this.show('error', msg)
  },
  success(msg) {
    this.show('success', msg)
  },
  warn(msg) {
    this.show('warning', msg)
  },
  addSuccess() {
    this.success('Add new successfully!')
  },
  addError() {
    this.error('Add new fail!')
  },
  updateSuccess() {
    this.success('Update successfully!')
  },
  updateError() {
    this.error('Update fail!')
  },
  deleteSuccess() {
    this.success('Delete successfully!')
  },
  deleteError() {
    this.error('Delete fail!')
  },
}
