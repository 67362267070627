import { isEmpty } from 'lodash'
export default {
  filterObject(obj = {}, filter = Boolean) {
    const output = {}
    if (obj['not-clear-request']) return obj
    for (let key in obj) {
      if (filter(obj[key], key) || obj[key] === 0) {
        output[key] = obj[key]
      }
    }
    return output
  },
  clone(source) {
    if (typeof source !== 'object') return source
    const target = Array.isArray(source) ? [] : {}
    _deepClone(source, target)
    return target
  },
  csvJSON(csv, headers = ['address'], defaultValue = '') {
    var lines = csv.split('\n')
    var result = []
    for (var i = 1; i < lines.length; i++) {
      if (isEmpty(lines[i])) continue
      var obj = {}
      var currentline = lines[i].trim().split(',')
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j] || defaultValue
      }
      result.push(obj)
    }
    return result
  },
}
const _deepClone = (source, target) => {
  for (let key in source) {
    // eslint-disable-next-line no-prototype-builtins
    if (!source.hasOwnProperty(key)) continue
    if (!source[key] || typeof source[key] !== 'object') {
      target[key] = source[key]
    } else {
      target[key] = Array.isArray(source[key]) ? [] : {}
      _deepClone(source[key], target[key])
    }
  }
}
