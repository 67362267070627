var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center justify-space-between my-6"},[_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v("All Projects ("+_vm._s(_vm.fixedPools.length)+")")]),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"text-none elevation-0",attrs:{"rounded":"","color":"primary"},on:{"click":function($event){_vm.addPoolState = !_vm.addPoolState}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Create new project")],1)],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.fixedPools},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('PoolListActions',{attrs:{"item":item}})]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mmddyyyyhhmm")(item.startDate))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('ChainLogo',{attrs:{"chain":_vm.getChain(item),"height":"15"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.ratio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("1 " + (item.tokenName) + " = " + (item.ratio) + " " + (item.tradeToken)))+" ")]}},{key:"item.pausedStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getPausedStatusColor(item.pausedStatus),"small":""}},[_vm._v(" "+_vm._s(item.pausedStatus || 'waiting deploy'))])]}},{key:"item.contractOwner",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getConnectedState(item) ? 'success' : ''},[_vm._v(" "+_vm._s(item.contractOwner)+_vm._s(item.contractOwner ? ("(" + (_vm.getConnectedState(item) ? 'connected' : 'disconnected') + ")") : '')+" ")])]}}],null,true)}),_c('FixedPoolAddDialog',{attrs:{"state":_vm.addPoolState}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }