import moment from 'moment'
import Vue from 'vue'
export const vueFilterRegister = () => {
  Vue.filter('mmddyyyyhhmm', (isoStr) => (isoStr ? moment(isoStr).format('MM-DD-YYYY, HH:mm') : ''))
  Vue.filter('formatNumber', (number, maximumFractionDigits = 5, minimumFractionDigits = 0) => {
    const nf = new Intl.NumberFormat('en-US', {
      maximumFractionDigits,
      minimumFractionDigits,
    })
    return nf.format(number)
  })
  Vue.filter('truncateAddress', (address = '', start = 6, end = 4) => {
    if (!address) return ''
    return address.substr(0, start) + '...' + address.substr(address.length - end, end)
  })
}
