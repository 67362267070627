<template>
  <v-dialog v-model="dialog" width="400px" :fullscreen="$vuetify.breakpoint.smAndDown">
    <v-card>
      <v-card-title color="red">Delete project?</v-card-title>
      <v-card-text>
        The project
        <span class="font-weight-bold black--text">"{{ fixedPool.name }}"</span>
        will be deleted. Are you sure?
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between pa-3">
        <v-btn small depressed class="red white--text" @click="onDelete()">Delete</v-btn>
        <v-btn small depressed outlined @click="dialog = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  components: {},
  props: {
    fixedPool: Object,
    state: Boolean,
  },
  data() {
    return {
      dialog: false,
      loading: false,
    }
  },
  computed: {},
  methods: {
    ...mapActions('fixedPools', ['removeFixedPool']),
    async onDelete() {
      this.$dialog.confirm({
        title: 'Delete Project',
        text: 'Are you sure deleting project ?',
        okText: 'Yes',
        cancelText: 'No',
        done: async () => {
          try {
            this.$loading.active = true
            await this.removeFixedPool(this.fixedPool.id)
            this.dialog = false
          } catch (error) {
            this.$alert.deleteError()
            console.error("Error onDelete:", error.message)
          } finally {
            this.$loading.active = false
          }
        },
      })
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    state(state) {
      this.dialog = true
    },
  },
}
</script>
