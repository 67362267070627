<template>
  <router-view></router-view>
</template>

 <script>
import { mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions('auth', ['init']),
  },
  async created() {
    await this.init()
  },
}
</script>

<style lang="scss">
input {
  margin: 0px 0px !important;
}
.theme--light {
  .v-sheet .v-sheet {
    border: 1px solid #d2d3db !important;
  }
}

.required label::after {
  content: ' *';
  color: red;
}
.v-data-table-header {
  background-color: #f5f5f5;
  border-bottom: none;
}
.content-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #757575;
}
.section-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
}
.field-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  color: #757575 !important;
}
.field-number {
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
}
.primary-border {
  border: 1px solid var(--v-primary-base) !important;
}
.border-radius-32 {
  border-radius: 32px !important;
}
.border-radius-16 {
  border-radius: 16px !important;
}
.border-radius-8 {
  border-radius: 8px !important;
}
</style>
