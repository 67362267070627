<template>
  <v-dialog v-model="dialog" max-width="920px" persistent>
    <v-card>
      <v-card-title class="primary white--text"
        ><v-toolbar-title>Check All Whitelist KYC</v-toolbar-title>
        <v-spacer />
        <v-icon color="white" @click="cancel" :disabled="isChecking || isChecked">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="py-6" v-if="!isChecking && !isChecked">
        <div class="text-center">
          <div class="text-h5 my-8">Click "Start" button below to start checking all whitelist KYC</div>
          <v-btn class="text-none btn-text elevation-0 px-10 py-5" @click="startCheckKYC" color="primary" rounded>
            Start
          </v-btn>
        </div>
      </v-card-text>
      <v-card-text class="d-flex flex-column align-center justify-center py-16" v-else-if="isChecking && !isChecked">
        <v-progress-circular :rotate="360" :size="120" :width="10" indeterminate color="primary"></v-progress-circular>
        <div class="mt-6 section-title primary--text">Checking all whitelist...</div>
      </v-card-text>
      <v-card-text v-else>
        <div class="d-flex align-center justify-center my-4">
          <div class="text-center px-4">
            <div class="field-title">Filled KYC accounts</div>
            <div class="field-number">{{ result.kycFilledCount }}</div>
          </div>
          <v-divider vertical></v-divider>
          <div class="text-center px-4">
            <div class="field-title">Not KYC accounts</div>
            <div class="field-number">{{ result.nonKycFilledCount }}</div>
          </div>
          <v-divider vertical></v-divider>
          <div class="text-center px-4">
            <div class="field-title">Error when check</div>
            <div class="field-number">{{ result.errorWhitelistCount }}</div>
          </div>
        </div>
        <div class="mt-6">
          <v-text-field
            v-model="walletSearch"
            class="border-radius-16"
            placeholder="Find Wallet Address"
            @input="searchWalletAddress"
            color="primary"
            outlined
          ></v-text-field>
        </div>
        <v-data-table :items-per-page="itemsPerPage" :headers="headers" :items="filledKYCWhitelists">
          <template v-slot:[`item.tierType`]="{ item }">
            {{ item | getTierType }}
          </template>
          <template v-slot:[`item.walletAddress`]="{ item }">
            {{ item.user ? item.user.walletAddress : '' | truncateAddress(10, 6) }}
          </template>
          <template v-slot:[`item.crosschainAddress`]="{ item }">
            {{ item.isCrosschain ? item.crosschainUser.walletAddress : '' | truncateAddress(10, 6) }}
          </template>
          <template v-slot:[`item.isWhitelisted`]="{ item }">
            <v-icon v-if="item.isWhitelisted" color="success">mdi-check-circle</v-icon>
            <v-icon v-else color="error">mdi-close-circle</v-icon>
          </template>
          <template v-slot:[`item.isKycFilled`]="{ item }">
            <v-icon v-if="item.isKycFilled" color="success">mdi-check-circle</v-icon>
            <v-icon v-else color="error">mdi-close-circle</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex align-center px-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn
          class="border-radius-8 elevation-0 text-none btn-text px-4"
          color="primary"
          v-if="!isChecking && isChecked"
          @click="doneCheck()"
          >Done</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const originalHeaders = [
  {
    text: 'Wallet Address',
    align: 'start',
    sortable: false,
    value: 'walletAddress',
  },
  {
    text: 'Crosschain Address',
    align: 'start',
    sortable: false,
    value: 'crosschainAddress',
  },
  {
    text: 'TierType',
    align: 'start',
    sortable: false,
    value: 'tierType',
  },
  {
    text: 'Is Whitelisted',
    align: 'center',
    sortable: false,
    value: 'isWhitelisted',
  },
  {
    text: 'Is KYC filled',
    align: 'center',
    sortable: false,
    value: 'isKycFilled',
  },
]
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    state: Boolean,
    poolId: String,
  },
  data() {
    return {
      dialog: false,
      isChecking: false,
      isChecked: false,
      headers: originalHeaders,
      itemsPerPage: 10,
      walletSearch: '',
      result: {},
      filledKYCWhitelists: [],
    }
  },
  watch: {
    state(state) {
      this.dialog = state
    },
  },
  computed: {
    ...mapState('whitelist', ['progress']),
  },
  filters: {
    getTierType: (item) => {
      switch (item.tierType) {
        case 0:
          return 'Social'
        case 1:
          return 'Mini Bee'
        case 2:
          return 'Worker Bee'
        case 3:
          return 'Drone Bee'
        case 4:
          return 'Queen Bee'
        default:
          return 'Social'
      }
    },
  },
  methods: {
    ...mapActions('whitelist', ['updateWhitelists', 'checkAllWhitelistKYC']),
    cancel() {
      this.$emit('closeDialog')
    },
    searchWalletAddress(searchKey) {
      if (!this.result || !this.result.kycFilledWhitelists) return []
      this.filledKYCWhitelists = this.result.kycFilledWhitelists.filter((whitelist) => {
        if (!searchKey) return true
        else if (
          whitelist.isCrosschain &&
          whitelist.crosschainUser &&
          whitelist.crosschainUser.walletAddress.includes(this.walletSearch)
        )
          return true
        else if (whitelist.user.walletAddress.includes(this.walletSearch)) return true
        return false
      })
    },
    async startCheckKYC() {
      if (!this.poolId) {
        this.$alert.error('Unknown Pool')
        return
      }
      this.isChecking = true
      this.result = await this.checkAllWhitelistKYC(this.poolId)
      this.searchWalletAddress('')
      this.isChecking = false
      this.isChecked = true
    },
    doneCheck() {
      this.$emit('closeDialog')
      this.$emit('doneCheck')
      this.result = {}
    },
  },
}
</script>