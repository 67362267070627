const { BN, Program } = require('@project-serum/anchor')
const anchor = require('@project-serum/anchor')
const utf8 = anchor.utils.bytes.utf8
const { getSolanaConfig } = require('./utils')
const provider = getSolanaConfig(true)

const farmv1 = require('./abis/farm-solana.v1.json')
const { PublicKey } = require('@solana/web3.js')
const FARM_ID = new PublicKey('2PB1GQHQTQtj6WgPTjNnmVc6r8WP9EUQw4SoniABCp1v')
const program = new Program(farmv1, FARM_ID, provider)
const { findLast } = require('lodash')

const lst = [
  {
    amount: 100,
    tierType: 1,
  },
  {
    amount: 1000,
    tierType: 2,
  },
  {
    amount: 5000,
    tierType: 3,
  },
  {
    amount: 10000,
    tierType: 4,
  },
]

let rewardMint

async function verifyTier(address, tier) {
  const res = await getAccountTier(address)
  return res && res >= tier
}
async function getAccountTier(address) {
  if (!rewardMint) {
    const state = await getStateAccount()
    rewardMint = state.rewardMint
  }
  const user = await getUserAccount(address)
  if (!user) return false
  const amount = user.amount.div(new BN(10).pow(new BN(9))).toNumber()
  const res = findLast(lst, (x) => amount >= x.amount)
  return res.tierType
}
async function getStateAccount() {
  const stateSigner = await getStateSigner()
  const info = await program.account.stateAccount.fetch(stateSigner)
  return info
}
// async function getPoolAccount() {
//   return await program.account.farmPoolAccount.fetch(await getPoolSigner())
// }
async function getStateSigner() {
  const [_poolSigner] = await anchor.web3.PublicKey.findProgramAddress([utf8.encode('state')], program.programId)
  return _poolSigner
}
async function getPoolSigner() {
  const [_poolSigner] = await anchor.web3.PublicKey.findProgramAddress([rewardMint.toBuffer()], program.programId)
  return _poolSigner
}
async function getUserSigner(address) {
  const accountId = new PublicKey(address)
  const [userAccount, bump] = await PublicKey.findProgramAddress(
    [(await getPoolSigner()).toBuffer(), accountId.toBuffer()],
    program.programId
  )
  return { userAccount, bump }
}
async function getUserAccount(account) {
  const user = await getUserSigner(account)
  try {
    return await program.account.farmPoolUserAccount.fetch(user.userAccount)
  } catch (error) {
    return null
  }
}
async function getAllUserAccount() {
  let users = await program.account.farmPoolUserAccount.all()
  users = users.map((user) => {
    const amount = user.account.amount.div(new BN(10).pow(new BN(9))).toNumber()
    const res = findLast(lst, (x) => amount >= x.amount)
    return {
      ...user,
      tier: res ? res.tierType : 0,
      walletAddress: user.account.authority.toString(),
    }
  })
  return users
}

module.exports = {
  verifyTier,
  getAccountTier,
  getAllUserAccount,
}
