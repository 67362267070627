import Web3 from 'web3'
import { isNumber } from 'lodash'
const chainIdList = [1, 3, 56, 97]

const getChainConfig = (chainId) => {
  chainId = isNumber(chainId) ? +chainId : chainId
  let rpc = ''
  let name = ''
  switch (chainId) {
    case 1:
    case 'eth':
      name = 'Ethereum Mainnet'
      rpc = 'https://speedy-nodes-nyc.moralis.io/6fa069a27f1f69de0c130e79/eth/mainnet'
      // rpc = 'https://cloudflare-eth.com'
      break
    case 3:
      name = 'Ropsten Test Network'
      rpc = 'https://eth-ropsten.alchemyapi.io/v2/4szhG-FVK337Gq63VnnPoB3VH2BLYIQE'
      break
    case 56:
    case 'bsc':
      name = 'Binance Smart Chain'
      rpc = 'https://bsc-dataseed.binance.org'
      //backup
      // rpc = 'https://bsc-dataseed1.defibit.io/'
      break
    case 97:
      name = 'Binance Smart Chain TestNET'
      rpc = 'https://data-seed-prebsc-2-s3.binance.org:8545/'
      break
  }
  return { rpc, name }
}

const getWeb3 = (chainId) => {
  chainId = isNumber(chainId) ? +chainId : chainId
  console.log('chainId', chainId)
  const { rpc } = getChainConfig(chainId)
  console.log('rpc', rpc)
  if (rpc) return new Web3(new Web3.providers.HttpProvider(rpc))
  else return null
}

export const blockchainHandler = {
  getChainConfig,
  getWeb3,
  chainIdList,
}
