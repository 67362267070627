import { FixedNumber } from '@ethersproject/bignumber'

const json = require('./FixedSwap.json')
const Web3 = require('web3')
export const web3 = new Web3(window.ethereum)

async function sendRequest(fx, from) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on('receipt', (res) => resolve(res))
      .on('error', (error) => reject(error))
  })
}

export class ContractHandler {
  async deployContract(account, payload) {
    const tradeValue = web3.utils.toWei(payload.tradeValue)
    const tokensForSale = web3.utils.toWei(payload.tokensForSale)
    const fixedSwapContract = new web3.eth.Contract(json.abi)
    const latestPayload = {
      data: json.bytecode,
      arguments: [
        payload.tokenAddress,
        payload.tradeTokenAddress,
        tradeValue,
        tokensForSale,
        [payload.startDate, payload.endDate],
        payload.hasWhiteListing,
        payload.farmAddress,
        payload.ownerAddress,
        payload.userTax,
        payload.sellerTax,
      ],
    }
    const contractInstance = fixedSwapContract.deploy(latestPayload)
    const res = await sendRequest(contractInstance, account)
    return res
  }

  async approvedContract(account, tokenAddress, contractAddress) {
    try {
      const tokenContract = new web3.eth.Contract(require('@/helpers/erc20.abi.json'), tokenAddress)
      const allowance = await tokenContract.methods.allowance(account, contractAddress).call()
      return !!+web3.utils.fromWei(allowance)
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async approveContract(account, tokenAddress, contractAddress) {
    const tokenContract = new web3.eth.Contract(require('@/helpers/erc20.abi.json'), tokenAddress)
    const f = tokenContract.methods.approve(contractAddress, web3.utils.toWei(`${2 ** 64 - 1}`))
    const res = await sendRequest(f, account)
    return res
  }

  async paused(contract) {
    if (!contract) return
    const res = await contract.methods.paused().call()
    return res
  }

  async getUpdateContractInfo(contract) {
    if (!contract) return
    const [
      startDate,
      endDate,
      tradeValue,
      tokensForSale,
      tokensFund,
      hasWhitelisting,
      paused,
      erc20,
      redeemConfigs,
      amountConfigs,
      userTax,
      sellerTax,
    ] = await Promise.all([
      contract.methods.startDate().call(),
      contract.methods.endDate().call(),
      contract.methods.tradeValue().call(),
      contract.methods.tokensForSale().call(),
      contract.methods.tokensFund().call(),
      contract.methods.hasWhitelisting().call(),
      contract.methods.paused().call(),
      contract.methods.erc20().call(),
      this.getRedeemConfig(contract),
      this.getAmountConfig(contract),
      contract.methods.userTax().call(),
      contract.methods.sellerTax().call(),
    ])
    return {
      startDate,
      endDate,
      tradeValue: web3.utils.fromWei(tradeValue),
      tokensForSale: web3.utils.fromWei(tokensForSale),
      tokensFund: FixedNumber.from(`${web3.utils.fromWei(tokensFund)}`),
      hasWhitelisting,
      paused,
      erc20,
      redeemConfigs,
      amountConfigs,
      userTax,
      sellerTax,
    }
  }

  async getRedeemConfig(contract) {
    const redeemConfigLength = await contract.methods.getRedeemConfigLength().call()
    const promises = []
    for (var i = 0; i < redeemConfigLength; i++) {
      promises.push(this.getRedeemConfigAt(contract, i))
    }
    return await Promise.all(promises)
  }
  async getRedeemConfigAt(contract, i) {
    const res = await contract.methods.redeemConfigs(i).call()
    return { id: i, redeemDate: res[1], redeemPercent:res[2], allowUpdate: true, pickerTime: '' }
  }
  async getAmountConfig(contract) {
    const amountConfigLength = await contract.methods.getAmountConfigLength().call()
    const promises = []
    for (var i = 0; i < amountConfigLength; i++) {
      promises.push(this.getAmountConfigAt(contract, i))
    }
    return await Promise.all(promises)
  }
  async getAmountConfigAt(contract, i) {
    const res = await contract.methods.individualAmounts(i).call()
    return {
      amount: web3.utils.fromWei(res[0]),
      lpAmount: web3.utils.fromWei(res[1]),
      allowUpdate: true,
    }
  }
  async changeStartDate(contract, startDate, account) {
    const f = contract.methods.changeStartDate(startDate)
    return sendRequest(f, account)
  }
  async changeTokensForSale(contract, tokensForSale, account) {
    const f = contract.methods.changeTokensForSale(web3.utils.toWei(tokensForSale))
    return sendRequest(f, account)
  }
  async changeSellerTax(contract, sellerTax, account) {
    const f = contract.methods.changeSellerTax(sellerTax)
    return sendRequest(f, account)
  }
  async changeUserTax(contract, userTax, account) {
    const f = contract.methods.changeUserTax(userTax)
    return sendRequest(f, account)
  }
  async changeEndDate(contract, endDate, account) {
    const f = contract.methods.changeEndDate(endDate)
    return sendRequest(f, account)
  }
  async changeErcToken(contract, erc20, account) {
    const f = contract.methods.changeErcToken(erc20)
    return sendRequest(f, account)
  }
  async fund(contract, amount, account) {
    const f = contract.methods.fund(web3.utils.toWei(amount))
    return sendRequest(f, account)
  }
  async unpause(contract, account) {
    const f = contract.methods.unpause()
    return sendRequest(f, account)
  }
  async pause(contract, account) {
    const f = contract.methods.pause()
    return sendRequest(f, account)
  }
  async withdrawFunds(contract, account) {
    const f = contract.methods.withdrawFunds()
    return sendRequest(f, account)
  }
  async withdrawUnsoldTokens(contract, account) {
    const f = contract.methods.withdrawUnsoldTokens()
    return sendRequest(f, account)
  }
  async removeOtherERC20Tokens(contract, tokenAddress, to, account) {
    const f = contract.methods.removeOtherERC20Tokens(tokenAddress, to)
    return sendRequest(f, account)
  }

  async setRedeemConfigs(contract, redeemConfigs, account) {
    let configs = []
    redeemConfigs.map((item) => {
      configs.push({ id: item.id, date: item.redeemDate, percentage: item.redeemPercent })
    })
    const f = contract.methods.setRedeemConfigs(configs)
    return sendRequest(f, account)
  }

  async setAmountConfigs(contract, amountConfigs, account) {
    let configs = []
    amountConfigs.map((item) => {
      configs.push({ amount: web3.utils.toWei(item.amount), lpAmount: web3.utils.toWei(item.lpAmount) })
    })
    const f = contract.methods.setAmountConfigs(configs)
    return sendRequest(f, account)
  }
  addWhitelist(contract, addresses, account) {
    const f = contract.methods.add(addresses)
    return sendRequest(f, account)
  }
}

const contractHandler = new ContractHandler()
export default contractHandler
