<template>
  <v-form ref="form">
    <div class="text-h5 mb-6 black--text">Individual Amounts</div>
    <v-row v-for="(item, index) in amountConfigs" :key="index">
      <v-col cols="12" class="py-0 mb-2">
        <v-sheet outlined color="primary" rounded style="position: relative">
          <v-icon
            style="position: absolute; top: 0; right: 0; z-index: 100"
            text
            @click="removeRow(index)"
            :disabled="index < originalLength || index === 0"
            >mdi-close</v-icon
          >
          <v-card outlined elevation="0" class="pa-4 pb-0">
            <v-row class="pa-0">
              <v-col cols="6" class="pb-1">
                <v-text-field
                  class="required"
                  :rules="[$rules.required, $rules.positiveNumber]"
                  v-model="item.amount"
                  label="Purchaseable amount"
                  dense
                  outlined
                  required
                />
              </v-col>
              <v-col cols="6" class="pb-1">
                <v-text-field
                  class="required"
                  :rules="[$rules.required, $rules.positiveNumber]"
                  v-model="item.lpAmount"
                  label="Avaiable required token amount "
                  dense
                  outlined
                  required
                />
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
    <div class="d-flex d-flex justify-space-between mt-3">
      <v-btn
        small
        class="primary rounded-lg text-capitalize"
        :loading="amountConfigsLoading"
        @click="setAmountConfigs()"
        >Change amount configs</v-btn
      >
      <v-btn small @click="addRow" class="dark-gray rounded-lg"><v-icon>mdi-plus</v-icon> Add row</v-btn>
    </div>
  </v-form>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import contractHandler from '@/helpers/ContractHandler'
export default {
  props: {
    state: Boolean,
  },
  components: {},
  data() {
    return {
      amountConfigs: [{ amount: '0', lpAmount: '0' }],
      amountConfigsLoading: false,
      originalLength: 0,
    }
  },
  computed: {
    ...mapState('fixedPools', ['contractInfo']),
    ...mapState('auth', ['account']),
  },
  methods: {
    ...mapActions('auth', ['connectMetamask']),
    removeRow(index) {
      if (this.amountConfigs.length > 1) this.amountConfigs.splice(index, 1)
    },
    addRow() {
      this.amountConfigs.push({ amount: '0', lpAmount: '0' })
    },
    reset() {
      this.$refs.form && this.$refs.form.resetValidation()
      this.originalLength = !this.contractInfo.amountConfigs ? 0 : this.contractInfo.amountConfigs.length
      if (this.originalLength === 0) {
        this.amountConfigs = [{ amount: '0', lpAmount: '0' }]
      } else {
        this.amountConfigs = this.contractInfo.amountConfigs
      }
    },
    async setAmountConfigs() {
      if (!this.$refs.form.validate()) return
      try {
        this.amountConfigsLoading = true
        await contractHandler.setAmountConfigs(this.contractInfo.contract, this.amountConfigs, this.account)
        this.contractInfo.amountConfigs = this.amountConfigs
        this.$store.commit('fixedPools/changeState', { contractInfo: this.contractInfo })
        this.originalLength = this.amountConfigs.length
        this.$alert.success('Update amountConfigs success!')
      } catch (e) {
        this.$alert.error(e.message)
        console.error(e)
      } finally {
        this.amountConfigsLoading = false
      }
    },
  },
  watch: {
    state: {
      handler(state) {
        if (state) {
          this.reset()
        }
      },
      immediate: true,
    },
  },
}
</script>