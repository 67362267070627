const { Connection, clusterApiUrl } = require('@solana/web3.js')
const { Provider } = require('@project-serum/anchor')

const getSolanaConfig = (mainet = process.env.NODE_ENV === 'production') => {
  const anchorWallet = new MyAnchorWallet(undefined)
  const opts = {
    preflightCommitment: 'recent',
    commitment: 'recent',
  }
  let connection

  if (mainet) {
    // connection = new Connection(clusterApiUrl('mainnet-beta'), opts.preflightCommitment)
    connection = new Connection('https://solana-api.projectserum.com', opts.preflightCommitment)
  } else {
    connection = new Connection(clusterApiUrl('devnet'), opts.preflightCommitment)
  }
  return new Provider(connection, anchorWallet, opts)
}

class MyAnchorWallet {
  constructor(adatper) {
    this.adapter = adatper
  }

  async signTransaction(tx) {
    console.log('signTransaction')
    return await this.adatper.signTransaction(tx)
  }
  async signAllTransactions(txs) {
    return await this.adatper.signAllTransactions(txs)
  }
  get publicKey() {
    return this.adatper.publicKey
  }
}

module.exports = {
  getSolanaConfig,
  MyAnchorWallet,
}
