<template>
  <v-app>
    <v-main>
      <plugin-alert />
      <plugin-loading />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import PluginAlert from '@/components/plugin/PluginAlert'
import PluginLoading from '@/components/plugin/PluginLoading'

export default {
  components: {
    PluginAlert,
    PluginLoading,
  },
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
}
</script>
