class BigNumberHelper {
  /**
   * Greater than
   */
  gt(first, second) {
    const diff = first.subUnsafe(second)
    return !diff.isZero() && !diff.isNegative()
  }

  gte(first, second) {
    const diff = first.subUnsafe(second)
    return !diff.isNegative()
  }

  /**
   * Less than
   */
  lt(first, second) {
    return first.subUnsafe(second).isNegative()
  }

  lte(first, second) {
    const diff = first.subUnsafe(second)
    return diff.isZero() || diff.isNegative()
  }
}

export const bigNumberHelper = new BigNumberHelper()
