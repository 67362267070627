const { getAllUserAccount } = require('../helpers/waggleFarm')

export default {
  namespaced: true,
  state: {
    stakeUsers: [],
  },
  actions: {
    async fetchStakeUsers({ commit }) {
      const stakeUsers = await getAllUserAccount()
      commit('setStakeUsers', stakeUsers)
    },
  },
  mutations: {
    setStakeUsers(state, stakeUsers) {
      state.stakeUsers = stakeUsers.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.walletAddress]: currentValue,
        }),
        {}
      )
    },
  },
}
