import { Apply, User } from '@/plugins/api'
import alert from '@/plugins/alert'
import { chunk } from 'lodash'

export default {
  namespaced: true,
  state: {
    allApplies: {},
    applies: [],
    apply: {},
    itemsPerPage: 10,
    count: 0,
    whitelistedCount: 0,
    filledKYCCount: 0,
    usedReferralCount: 0,
    applySearchParams: {},
    progress: 0,
    loading: true,
    loadedReferenceCount: 0,
  },
  actions: {
    async fetchApplies({ commit, state }, { page, itemsPerPage, ...options }) {
      if (!page) page = 1
      const applySearchParams = {
        ...state.applySearchParams,
        _limit: itemsPerPage,
        _start: (page - 1) * itemsPerPage,
        ...options,
      }
      if (!itemsPerPage) itemsPerPage = state.itemsPerPage
      const [applies, count, whitelistedCount, filledKYCCount, usedReferralCount] = await Promise.all([
        Apply.search({ ...applySearchParams }),
        Apply.count({
          ...applySearchParams,
        }),
        Apply.count({
          ...applySearchParams,
          status: 'whitelisted',
        }),
        Apply.count({
          ...applySearchParams,
          'investor.status': 'kyc-verified',
        }),
        Apply.count({
          ...applySearchParams,
          referralUser: { $exists: true },
          referralUser_null: false,
        }),
      ])
      console.log('usedReferralCount', usedReferralCount)
      commit('changeState', {
        applies,
        count,
        whitelistedCount,
        filledKYCCount,
        usedReferralCount,
        applySearchParams,
      })
    },
    // async checkAllWhitelistKYC({ commit }, poolId) {
    //   try {
    //     commit('changeState', {})
    //     return await Apply.checkWhitelistKYC(poolId)
    //   } catch (error) {
    //     alert.error(error)
    //   }
    // },
    async fetchApply({ commit }, id) {
      const apply = await Apply.fetchOne(id)
      commit('changeState', { apply })
    },
    async fetchAllApplies({ commit, state, dispatch }, options) {
      try {
        state.loading = true
        let applies = await Apply.fetch({ ...options, _limit: -1 })
        applies = applies.map((apply) => ({ ...apply, isLoadingReference: true, referenceCount: 0, tier: 0 }))
        commit('setAllApplies', applies)
        state.loading = false
        const applyGroups = chunk(applies, 35)
        for (let i = 0; i < applyGroups.length; i++) {
          const applyGroup = applyGroups[i]
          const promises = applyGroup.map(async (apply) => {
            try {
              if (!apply.investor) return
              await dispatch('fetchApplyReferenceCount', apply)
            } catch (error) {
              //
            }
          })
          await Promise.all(promises)
        }
      } catch (error) {
        alert.error(error)
      } finally {
        state.loading = false
      }
    },
    async fetchApplyReferenceCount({ commit, state }, apply) {
      if (!apply || !apply.walletAddress) return
      const referenceUsers = await User.fetch({
        username: apply.walletAddress,
      })
      if (!referenceUsers || !referenceUsers.length === 0) return
      const referenceUser = referenceUsers[0]
      const doneTaskCount = await Apply.count({
        referralUser: referenceUser.id,
        pool: apply.pool.id,
        status: 'done-tasks',
      })
      const highTierCount = await Apply.count({
        reference: apply.investor.id,
        pool: apply.pool.id,
        status: 'whitelisted',
      })
      commit('changeApplyDetail', {
        id: apply.id,
        changes: {
          referenceCount: doneTaskCount + highTierCount,
          isLoadingReference: false,
        },
      })
      commit('changeState', {
        loadedReferenceCount: state.loadedReferenceCount + 1,
      })
    },
    async updateApply({ commit }, { id, ...params }) {
      const updatedApply = await Apply.update(id, params)
      commit('changeApplyDetail', {
        id,
        changes: {
          ...params,
        },
      })
      alert.success('Update whitelist successfully!')
      return updatedApply
    },
    async updateApplies({ commit }, { applies, roundOption }) {
      let progress = 0
      const applyGroups = chunk(applies, 15)
      let invalidApplies = []
      for (let i = 0; i < applyGroups.length; i++) {
        const applyGroup = applyGroups[i]
        const promises = applyGroup.map(async (apply) => {
          try {
            const isWhitelisted = apply?.isWhitelisted?.toLowerCase() === 'yes'
            const roundType = {}
            if (isWhitelisted) {
              if (roundOption === 'communityRound') roundType.isCommunityRound = true
              else if (roundOption === 'tierRound') roundType.isTierRound = true
            }
            await Apply.update(apply.id, {
              status: isWhitelisted ? 'whitelisted' : apply.status ? apply.status : '',
              ...roundType,
            })
          } catch (error) {
            invalidApplies.push({
              id: apply.id,
              walletAddress: apply.walletAddress,
              message: { error },
            })
            console.error(error)
          }
        })
        progress = Math.floor(((i + 1) * 100) / applyGroups.length)
        await Promise.all(promises)
        commit('changeState', { progress })
      }
      return invalidApplies
    },
  },
  mutations: {
    setApply(state, apply) {
      state.apply = apply
    },
    setAllApplies(state, applies) {
      state.allApplies = applies.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.id]: currentValue,
        }),
        {}
      )
    },
    changeApplyDetail(state, { id, changes }) {
      if (state.allApplies[id]) state.allApplies[id] = { ...state.allApplies[id], ...changes }
    },
  },
  getters: {
    allApplies: (state) => {
      return Object.values(state.allApplies)
    },
    isLoading: (state) => {
      return state.loading
    },
    isLoadReferenceComplete: (state) => {
      return state.loadedReferenceCount === Object.values(state.allApplies).length
    },
  },
}
