export const inputRules = {
  required: (v) => (!!v && (typeof v !== 'string' || !!v.trim())) || v === 0 || 'Required value',
  maxLength: (length) => (v) => (v && v.length <= length) || !v || `Max length is ${length} character`,
  minLength: (length) => (v) => (v && v.length >= length) || !v || `Min length is ${length} character`,
  positiveNumber: (v) => !v || v === 'TBA' || (!!v && new RegExp(/^\d+(\.\d*)?$/).test(v)) || 'Invalid value',
  positiveInterger: (v) => !v || v === 'TBA' || (!!v && new RegExp(/^\d*$/).test(v)) || 'Invalid value',
  validTax: (v) => !v || (!!v && new RegExp(/^\d*$/).test(v)) || 'Invalid value',
  max: (number) => (v) => v <= number || `Must be lower than or equal to ${number || 0}`,
  min: (number) => (v) => v >= number || `Must be greater than or equal to ${number || 0}`,
  timestampGtCurrentTime: (v) => v * 1000 >= Date.now() || 'Must be the future time',
  timestampLimit: (v) => !v || (!!v && v < 1e13) || 'Invalid timestamp, the value is too big',
  email: (v) =>
    (v &&
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v.trim()
      )) ||
    !v ||
    'Email Format is incorrect',
}
