<template>
  <v-app>
    <v-navigation-drawer style="height: 100%" v-model="drawer" app>
      <div class="d-flex justify-center align-end py-6" style="height: 100% !important">
        <v-btn class="primary mt-5 text-none elevation-0" @click="signOut" rounded>Sign Out</v-btn>
      </div>
    </v-navigation-drawer>

    <v-app-bar app class="text-h5 elevation-0">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 100%" class="d-flex align-center justify-space-between">
        <div>Waggle Network</div>
        <div class="d-flex">
          <v-btn
            v-if="!walletConnected"
            color="primary"
            class="d-none d-sm-flex ml-4 text-none"
            @click="connectMetamask()"
            rounded
            outlined
            >Connect Wallet</v-btn
          >
          <div v-else class="d-none d-sm-flex address-container align-center">
            <div
              class="
                rounded-pill
                align-center
                justify-center
                d-flex
                lighten-1
                ml-2
                caption
                px-2
                py-1
                primary--text
                font-weight-medium
              "
            >
              <ChainLogo :chain="getChain" height="15" />
              <span class="ml-2"> {{ shortAccount }}</span>
              <v-btn icon x-small @click="copyAddress"><v-icon color="primary">mdi-content-copy</v-icon></v-btn>
            </div>
          </div>
        </div>
      </v-toolbar-title>
    </v-app-bar>

    <v-main class="d-print-none" style="background-color: #fafafa">
      <plugin-alert />
      <plugin-loading />
      <plugin-confirm-dialog />
      <v-divider></v-divider>
      <v-container
        id="container"
        fluid
        grid-list-xl
        class="page-container overflow-auto pa-0 pa-sm-2 pa-md-2 pa-xl-2 pa-lg-2"
        style="overflow-x: hidden"
      >
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import PluginAlert from '@/components/plugin/PluginAlert'
import PluginLoading from '@/components/plugin/PluginLoading'
import PluginConfirmDialog from '@/components/plugin/PluginConfirmDialog'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  data: () => ({ drawer: false }),
  components: {
    PluginAlert,
    PluginLoading,
    PluginConfirmDialog,
  },
  methods: {
    ...mapActions('auth', ['signOut', 'connectMetamask']),
    copyAddress() {
      navigator.clipboard.writeText(this.account)
    },
  },
  computed: {
    ...mapGetters('auth', ['shortAccount', 'walletConnected']),
    ...mapState('auth', ['account', 'chainId']),
    getChain() {
      switch (+this.chainId) {
        case 1:
        case 3:
          return 'eth'
        case 97:
        case 56:
          return 'bsc'
        default:
          return 'bsc'
      }
    },
  },
}
</script>
<style scoped>
.address-container {
  border-radius: 20px;
  border: 1px solid red !important;
  padding: 6px 10px;
}
.page-container {
  max-width: 1200px;
}
</style>
