<template>
  <v-row class="black--text">
    <v-col cols="6" class="py-0">
      Trade value: <span class="red--text">{{ contractInfo.tradeValue }}</span>
    </v-col>
    <v-col cols="6" class="py-0">
      Token for sale: <span class="red--text">{{ contractInfo.tokensForSale }}</span>
    </v-col>
    <v-col cols="6" class="py-0">
      Has whitelisting: <span class="red--text">{{ contractInfo.hasWhitelisting }}</span>
    </v-col>
    <v-divider class="my-4" />
    <v-col cols="12" class="pb-0">
      <v-form ref="whitelistForm">
        <v-sheet outlined rounded class="pa-3">
          <v-row>
            <v-col cols="6">
              <template>
                <v-file-input
                  dense
                  accept=".csv"
                  label="File input"
                  v-model="csvFile"
                  :rules="[$rules.required]"
                ></v-file-input>
              </template>
            </v-col>
            <v-col cols="6">
              <v-btn small class="primary" @click="addWhiteList" :loading="whiteListLoading">Add whitelist</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-form>
    </v-col>
    <v-col cols="12" class="pb-0">
      <v-sheet outlined rounded class="pa-2 pb-0">
        <v-row>
          <v-col cols="6">
            <v-form ref="startDateForm">
              <v-text-field
                class="required"
                :rules="[$rules.required, $rules.positiveInterger, $rules.timestampLimit]"
                v-model="startDate"
                dense
                label="Start date"
                outlined
                required
              ></v-text-field>
              <div>
                Original time: <span class="red--text">{{ contractInfo.startDate }}</span>
              </div>
            </v-form>
          </v-col>
          <v-col cols="6" class="pb-5 pt-0">
            <div>
              <v-datetime-picker
                v-model="startDatePicker"
                outline
                label="Select Datetime"
                @input="startDate = Date.parse(startDatePicker) / 1000"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar-range</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock-time-three</v-icon>
                </template>
              </v-datetime-picker>
              <div>
                Local Time: <span class="red--text">{{ this.startDate | timestampToLocalTime }}</span>
              </div>
              <div>
                GMT Time : <span class="red--text">{{ this.startDate | timestampToGMTTime }}</span>
              </div>
              <v-btn
                small
                class="primary rounded-lg text-capitalize"
                :loading="startDateLoading"
                @click="changeStartDate"
                >Change start date</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>

    <v-col cols="12">
      <v-sheet outlined rounded class="pa-2 pb-0">
        <v-row>
          <v-col cols="6">
            <v-form ref="endDateForm">
              <v-text-field
                class="required"
                :rules="[$rules.required, $rules.positiveInterger, $rules.timestampLimit]"
                v-model="endDate"
                dense
                label="End date"
                outlined
                required
              ></v-text-field>
              <div>
                Original time: <span class="red--text">{{ contractInfo.endDate }}</span>
              </div>
            </v-form>
          </v-col>

          <v-col cols="6" class="pb-5 pt-0">
            <div>
              <v-datetime-picker
                v-model="endDatePicker"
                outline
                label="Select Datetime"
                @input="endDate = Date.parse(endDatePicker) / 1000"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar-range</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock-time-three</v-icon>
                </template>
              </v-datetime-picker>
              <div>
                Local Time: <span class="red--text">{{ this.endDate | timestampToLocalTime }}</span>
              </div>
              <div>
                GMT Time : <span class="red--text">{{ this.endDate | timestampToGMTTime }}</span>
              </div>
              <v-btn small class="primary rounded-lg text-capitalize" :loading="endDateLoading" @click="changeEndDate"
                >Change end date</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>

    <v-col cols="12" class="py-0">
      <v-sheet outlined rounded class="pa-2">
        <v-row>
          <v-col cols="6">
            <div>
              Paused status: <span class="red--text">{{ contractInfo.paused }}</span>
            </div>
          </v-col>
          <v-col cols="6">
            <v-btn
              small
              class="primary rounded-lg text-capitalize mr-6 rounded-lg text-capitalize"
              :loading="pauseLoading"
              @click="onPause(true)"
              >paused</v-btn
            >
            <v-btn small class="primary rounded-lg text-capitalize" @click="onPause(false)" :loading="unpauseLoading"
              >unpause</v-btn
            >
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>

    <v-col cols="12">
      <v-sheet outlined rounded class="pa-3 pb-0">
        <v-row>
          <v-col cols="7" class="pb-0">
            <v-form ref="erc20Form">
              <v-text-field
                class="required"
                :rules="[$rules.required]"
                v-model="erc20"
                dense
                label="Erc20"
                outlined
                required
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="5" class="pb-0">
            <v-btn small class="primary rounded-lg text-capitalize" :loading="erc20Loading" @click="changeErcToken"
              >Change Erc20</v-btn
            >
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
    <v-col cols="12">
      <v-sheet outlined rounded class="pa-3 pb-0">
        <v-row>
          <v-col cols="7" class="pb-0">
            <v-form ref="userTaxForm">
              <v-text-field
                class="required"
                :rules="[$rules.required, $rules.validTax]"
                v-model="userTax"
                dense
                label="UserTax"
                outlined
                required
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="5" class="pb-0">
            <v-btn small class="primary rounded-lg text-capitalize" :loading="userTaxLoading" @click="changeUserTax"
              >Change UserTax</v-btn
            >
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
    <v-col cols="12">
      <v-sheet outlined rounded class="pa-3 pb-0">
        <v-row>
          <v-col cols="7" class="pb-0">
            <v-form ref="sellerTaxForm">
              <v-text-field
                class="required"
                :rules="[$rules.required, $rules.validTax]"
                v-model="sellerTax"
                dense
                label="SellerTax"
                outlined
                required
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="5" class="pb-0">
            <v-btn small class="primary rounded-lg text-capitalize" :loading="sellerTaxLoading" @click="changeSellerTax"
              >Change SellerTax</v-btn
            >
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
    <v-col cols="12">
      <v-sheet outlined rounded class="pa-3 pb-0">
        <v-row>
          <v-col cols="7" class="pb-0">
            <v-form ref="tokensForSaleForm">
              <v-text-field
                class="required"
                :rules="[$rules.required, $rules.positiveNumber]"
                v-model="tokensForSale"
                dense
                label="TokensForSale"
                outlined
                required
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="5" class="pb-0">
            <v-btn small class="primary rounded-lg text-capitalize" :loading="tokensForSaleLoading" @click="changeTokensForSale"
              >Change TokensForSale</v-btn
            >
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>

    <v-col cols="12">
      <v-sheet outlined rounded class="pa-3 pb-0">
        <v-form ref="removeOtherERC20TokensForm">
          <v-row>
            <v-col cols="7" class="pb-0">
              <v-text-field
                class="required"
                :rules="[$rules.required]"
                v-model="removedErc20TokenAddress"
                dense
                label="Token address"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="py-0"> </v-col>

            <v-col cols="7" class="py-0">
              <v-text-field
                class="required"
                :rules="[$rules.required]"
                v-model="erc20TokenReceivedAddress"
                dense
                label="Received address"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="py-0">
              <v-btn
                small
                class="primary rounded-lg text-capitalize mr-4"
                @click="removeOtherERC20Tokens"
                :loading="removeOtherERC20Loading"
                >Remove Other ERC20 Tokens</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-sheet>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-sheet outlined rounded class="pa-3">
        <div class="d-flex justify-space-between">
          <v-btn
            small
            class="primary rounded-lg text-capitalize mr-4"
            min-width="215px"
            @click="withdrawFunds"
            :loading="withdrawFundsLoading"
            >withdraw Funds</v-btn
          >
          <v-btn
            small
            class="primary rounded-lg text-capitalize mr-4"
            min-width="215px"
            @click="withdrawUnsoldTokens"
            :loading="withdrawUnsoldLoading"
            >withdraw Unsold Tokens</v-btn
          >
        </div>
      </v-sheet>
    </v-col>
    <v-col cols="12" class="pb-0">
      <v-sheet outlined rounded class="pa-3">
        <v-row>
          <v-col cols="12">
            <RedeemConfigForm ref="redeemConfigForm" :state="state" />
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
    <v-col cols="12" class="pb-0">
      <v-sheet outlined rounded class="pa-3">
        <v-row>
          <v-col cols="12">
            <IndividualAmountsForm ref="individualAmountsForm" :state="state" />
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
    <v-col cols="12" class="pb-0">
      <v-sheet outlined rounded class="pa-3">
        <v-row>
          <v-col cols="12">
            <ContractUpdateFundTokenForm :fixedPool="fixedPool" :state="state" />
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import contractHandler from '@/helpers/ContractHandler.js'
import RedeemConfigForm from './RedeemConfigForm.vue'
import IndividualAmountsForm from '../forms/IndividualAmountsForm'
import ContractUpdateFundTokenForm from '../forms/ContractUpdateFundTokenForm.vue'

const Web3 = require('web3')
import _ from 'lodash'
export default {
  components: { RedeemConfigForm, IndividualAmountsForm, ContractUpdateFundTokenForm },
  props: {
    state: Boolean,
    fixedPool: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      startDateLoading: false,
      endDateLoading: false,
      erc20Loading: false,
      pauseLoading: false,
      unpauseLoading: false,
      userTaxLoading: false,
      sellerTaxLoading: false,
      tokensForSaleLoading: false,
      withdrawFundsLoading: false,
      withdrawUnsoldLoading: false,
      removeOtherERC20Loading: false,
      sellerTax:'',
      userTax:'',
      tokensForSale:'',
      erc20: '',
      startDate: '',
      endDate: '',
      removedErc20TokenAddress: '',
      erc20TokenReceivedAddress: '',
      csvFile: null,
      whiteListLoading: false,
      endDatePicker: '',
      startDatePicker: '',

      rules: {
        // maxDate: (v) =>
        //   !v ||
        //   parseInt(v) <= parseInt(this.contractInfo.endDate) ||
        // `Start date must be before end date: ${this.contractInfo.endDate}`,
        //   minDate: (v) =>
        //     !v ||
        //     parseInt(v) >= parseInt(this.contractInfo.startDate) ||
        //     `End date must be after start date: ${this.contractInfo.startDate}`,
      },
    }
  },
  computed: {
    ...mapState('fixedPools', ['contractInfo']),
    ...mapState('auth', ['account']),
  },
  methods: {
    ...mapActions('auth', ['connectMetamask']),
    ...mapActions('fixedPools', ['updatePausedStatus']),
    getData() {
      if (!this.$refs.form.validate()) return null
      return this.data
    },
    // eslint-disable-next-line no-unused-vars
    dateTimePickerChange(pickerDateTime, target) {
      target = Date.parse(pickerDateTime) / 1000
    },
    async reset() {
      this.$refs.startDateForm && this.$refs.startDateForm.resetValidation()
      this.$refs.endDateForm && this.$refs.endDateForm.resetValidation()
      this.$refs.erc20Form && this.$refs.erc20Form.resetValidation()
      this.$refs.removeOtherERC20TokensForm && this.$refs.removeOtherERC20TokensForm.resetValidation()
      this.$refs.whitelistForm && this.$refs.whitelistForm.resetValidation()
      this.csvFile = null
      if (this.contractInfo) {
        this.erc20 = this.contractInfo.erc20
        this.startDate = this.contractInfo.startDate
        this.endDate = this.contractInfo.endDate
        this.userTax = this.contractInfo.userTax
        this.sellerTax = this.contractInfo.sellerTax
        this.tokensForSale = this.contractInfo.tokensForSale
      }
    },
    async addWhiteList() {
      // eslint-disable-next-line no-unused-vars
      if (!this.$refs.whitelistForm.validate()) return
      let reader = new FileReader()
      reader.readAsBinaryString(this.csvFile)
      reader.onload = async () => {
        try {
          this.whiteListLoading = true
          let wallets = this.$utils.csvJSON(reader.result)
          wallets = wallets.map((w) => ({ ...w, address: w.address ? w.address.toLowerCase() : '' }))
          wallets = _.uniqBy(wallets, (w) => w.address)
          wallets = wallets.filter((item) => {
            const res = Web3.utils.isAddress(item.address)
            if (!res) console.log('invalid address', item.address)
            return res
          })
          const walletChunks = _.chunk(wallets, 200)
          const promises = []
          for (let index = 0; index < walletChunks.length; index++) {
            const promise = contractHandler.addWhitelist(
              this.contractInfo.contract,
              walletChunks[index].map((a) => a.address.toLowerCase()),
              this.account
            )
            promises.push(promise)
          }
          await Promise.all(promises)
          this.$alert.success('Update Startdate success!')
        } catch (e) {
          this.$alert.error(e.message)
           console.error("addWhiteList() error: ", e.message)
        } finally {
          this.whiteListLoading = false
        }
      }
    },
    async changeStartDate() {
      if (!this.$refs.startDateForm.validate()) return
      try {
        this.startDateLoading = true
        await contractHandler.changeStartDate(this.contractInfo.contract, this.startDate, this.account)
        const contractInfo = { ...this.contractInfo, startDate: this.startDate }
        this.$alert.success('Update Startdate success!')
        this.$store.commit('fixedPools/changeState', { contractInfo })
      } catch (e) {
        this.$alert.error(e.message)
        console.error("Error changeStartDate():",e.message)
        
      } finally {
        this.startDateLoading = false
      }
    },
    async changeUserTax() {
      if (!this.$refs.userTaxForm.validate()) return
      try {
        this.userTaxLoading = true
        await contractHandler.changeUserTax(this.contractInfo.contract, this.userTax, this.account)
        // const contractInfo = { ...this.contractInfo, userTax: this.userTax }
        this.$alert.success('Update UserTax success!')
        // this.$store.commit('fixedPools/changeState', { contractInfo })
      } catch (e) {
        this.$alert.error(e.message)
        console.error("Error changeUserTax():",e.message)
      } finally {
        this.userTaxLoading = false
      }
    },
    async changeSellerTax() {
      if (!this.$refs.sellerTaxForm.validate()) return
      try {
        this.sellerTaxLoading = true
        await contractHandler.changeSellerTax(this.contractInfo.contract, this.sellerTax, this.account)
        // const contractInfo = { ...this.contractInfo, sellerTax: this.sellerTax }
        this.$alert.success('Update SellerTax success!')
        // this.$store.commit('fixedPools/changeState', { contractInfo })
      } catch (e) {
        this.$alert.error(e.message)
        console.error("Error changeSellerTax():",e.message)
      } finally {
        this.sellerTaxLoading = false
      }
    },
    async changeTokensForSale() {
      if (!this.$refs.tokensForSaleForm.validate()) return
      try {
        this.tokensForSaleLoading = true
        await contractHandler.changeTokensForSale(this.contractInfo.contract, this.tokensForSale, this.account)
        // const contractInfo = { ...this.contractInfo, tokensForSale: this.tokensForSale }
        this.$alert.success('Update TokensForSale success!')
        // this.$store.commit('fixedPools/changeState', { contractInfo })
      } catch (e) {
        this.$alert.error(e.message)
        console.error("Error changeTokensForSale():",e.message)
      } finally {
        this.tokensForSaleLoading = false
      }
    },
    async changeEndDate() {
      if (!this.$refs.startDateForm.validate()) return
      try {
        this.endDateLoading = true
        await contractHandler.changeEndDate(this.contractInfo.contract, this.endDate, this.account)
        const contractInfo = { ...this.contractInfo, endDate: this.endDate }
        this.$alert.success('Update Startdate success!')
        this.$store.commit('fixedPools/changeState', { contractInfo })
      } catch (e) {
        this.$alert.error(e.message)
        console.error("Error changeEndDate():",e.message)
      } finally {
        this.endDateLoading = false
      }
    },
    async changeErcToken() {
      if (!this.$refs.erc20Form.validate()) return
      try {
        this.erc20Loading = true
        await contractHandler.changeErcToken(this.contractInfo.contract, this.erc20, this.account)
        // const contractInfo = { ...this.contractInfo, erc20: this.erc20 }
        this.$alert.success('Update Startdate success!')
        // this.$store.commit('fixedPools/changeState', { contractInfo })
      } catch (e) {
        this.$alert.error(e.message)
        console.error("Error changeErcToken():",e.message)
      } finally {
        this.erc20Loading = false
      }
    },
    async onPause(status) {
      try {
        if (status) this.pauseLoading = true
        else this.unpauseLoading = true
        if (status) {
          await contractHandler.pause(this.contractInfo.contract, this.account)
        } else {
          await contractHandler.unpause(this.contractInfo.contract, this.account)
        }
        this.updatePausedStatus({ id: this.fixedPool.id, pausedStatus: status ? 'paused' : 'unpaused' })
        this.$alert.success('Update pause status success!')
      } catch (e) {
        this.$alert.error(e.message)
        console.error("Error onPause():",e.message)
      } finally {
        if (status) this.pauseLoading = false
        else this.unpauseLoading = false
      }
    },
    async withdrawFunds() {
      try {
        this.withdrawFundsLoading = true
        await contractHandler.withdrawFunds(this.contractInfo.contract, this.account)
        this.$alert.success('Withdraw funds success!')
      } catch (e) {
        this.$alert.error(e.message)
        console.error("Error withdrawFunds():",e.message)
      } finally {
        this.withdrawFundsLoading = false
      }
    },
    async withdrawUnsoldTokens() {
      try {
        this.withdrawUnsoldLoading = true
        await contractHandler.withdrawUnsoldTokens(this.contractInfo.contract, this.account)
        this.$alert.success('Withdraw unsold tokens success!')
      } catch (e) {
        this.$alert.error(e.message)
        console.error("Error withdrawUnsoldTokens():",e.message)
      } finally {
        this.withdrawUnsoldLoading = false
      }
    },
    async removeOtherERC20Tokens() {
      if (!this.$refs.removeOtherERC20TokensForm.validate()) return
      try {
        this.removeOtherERC20Loading = true
        await contractHandler.removeOtherERC20Tokens(
          this.contractInfo.contract,
          this.removedErc20TokenAddress,
          this.erc20TokenReceivedAddress,
          this.account
        )
        this.$alert.success('Remove erc20 tokens success!')
      } catch (e) {
        this.$alert.error(e.message)
        console.error("Error removeOtherERC20Tokens():",e.message)
      } finally {
        this.removeOtherERC20Loading = false
      }
    },
  },
  watch: {
    state: {
      handler(state) {
        if (state) {
          this.reset()
        }
      },
      immediate: true,
    },
  },
  filters: {
    timestampToLocalTime(time) {
      return moment(time * 1000)
        .local()
        .format('YYYY-MM-DD HH:mm')
    },
    timestampToGMTTime(time) {
      return moment(time * 1000)
        .utc()
        .format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>