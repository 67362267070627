<template>
  <v-form ref="form" class="pa-3">
    <div class="text-h5 mb-6">Fund tokens</div>
    <v-row>
      <v-col cols="12" class="py-0"
        ><div class="d-flex justify-end mt-0">
          <span class="text-caption grey--text">Balance {{ name }}</span>
        </div></v-col
      >
      <v-col cols="6" class="py-0">
        <v-text-field
          :rules="[$rules.required]"
          dense
          outlined
          required
          label="Token address"
          hide-details
          v-model="tokenAddress"
          class="text-medium no-padding-text-field transparent-text-field"
          :disabled = "true"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field
          :rules="[$rules.required]"
          dense
          hide-details
          outlined
          required
          placeholder="0"
          v-model="amount"
          class="required text-medium no-padding-text-field transparent-text-field"
        >
          <div slot="append" class="d-flex flex-column align-end">
            <div class="d-flex align-center">
              <v-btn x-small outlined rounded @click="amount = fundableToken._value" color="primary"> Max </v-btn>
              <span class="ml-3 black--text">{{ fundableToken | formatNumber }}</span>
            </div>
          </div>
        </v-text-field>
      </v-col>
      <v-col cols="6" class="pt-0">
        <div class="error-frame">{{ addressError ? 'Invalid address' : '' }}</div>
      </v-col>
      <v-col cols="6" class="pt-0">
        <div class="error-frame">{{ amountError ? 'Invalid amount' : '' }}</div>
      </v-col>

      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <v-btn
            class="primary"
            rounded
            min-width="25%"
            :disabled="approved || addressError"
            @click="approveContract"
            :loading="approveLoading"
            small
            >Approve</v-btn
          >
          <v-btn
            class="primary"
            rounded
            small
            min-width="25%"
            :disabled="!approved || addressError || amountError"
            @click="fundTokens"
            :loading="fundLoading"
            >Fund tokens</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import contractHandler, { web3 } from '@/helpers/ContractHandler'
import { bigNumberHelper } from '@/helpers/bignumber-helper'
import { FixedNumber } from '@ethersproject/bignumber'
const Web3 = require('web3')
const FIXEDNUMBER_0 = FixedNumber.from('0')
export default {
  props: {
    state: Boolean,
    fixedPool: {
      default: () => {},
      type: Object,
    },
  },
  components: {},
  data() {
    return {
      tokenAddress: '',
      approved: false,
      amount: '0',
      approveLoading: false,
      fundLoading: false,
      name: '',
    }
  },
  computed: {
    ...mapState('fixedPools', ['contractInfo']),
    ...mapState('auth', ['account']),
    fundableToken: function () {
      return this.contractInfo
        ? FixedNumber.from(`${this.contractInfo.tokensForSale}`).subUnsafe(this.contractInfo.tokensFund)
        : FIXEDNUMBER_0
    },
    addressError: function () {
      return !Web3.utils.isAddress(this.tokenAddress)
    },
    amountError: function () {
      if (!this.amount || !new RegExp(/^\d+(\.\d*)?$/).test(this.amount)) return true
      const amount = FixedNumber.from(this.amount)
      return bigNumberHelper.lt(amount, FIXEDNUMBER_0) || bigNumberHelper.gt(amount, this.fundableToken)
    },
  },
  methods: {
    ...mapActions('auth', ['connectMetamask']),
    async fundTokens() {
      try {
        this.fundLoading = true
        await contractHandler.fund(this.contractInfo.contract, this.amount, this.account)
        const amount = FixedNumber.from(this.amount)
        const contractInfo = {
          ...this.contractInfo,
          tokensFund: this.contractInfo.tokensFund.addUnsafe(amount),
        }
        this.$store.commit('fixedPools/changeState', { contractInfo })
        this.amount = '0'
        this.$alert.success('fund tokens successed')
      } catch (e) {
        this.$alert.error('fund error....!')
        console.error(e)
      } finally {
        this.fundLoading = false
      }
    },
    async reset() {
      this.tokenAddress= await this.contractInfo.contract.methods.erc20().call() 
      this.amount = '0'
      const tokenContract = new web3.eth.Contract(require('@/helpers/erc20.abi.json'), this.tokenAddress)
      this.name = await tokenContract.methods.name().call()
      await this.handleApproved()
    },
    async handleApproved() {
      this.approved = await contractHandler.approvedContract(this.account, this.tokenAddress, this.fixedPool.address)
    },
    async setAmountConfigs() {
      if (!this.$refs.form.validate()) return
      try {
        this.amountConfigsLoading = true
        await contractHandler.setAmountConfigs(this.contractInfo.contract, this.amountConfigs, this.account)
        this.contractInfo.amountConfigs = this.amountConfigs
        this.$store.commit('fixedPools/changeState', { contractInfo: this.contractInfo })
        this.originalLength = this.amountConfigs.length
        this.$alert.success('Update amountConfigs success!')
      } catch (e) {
        this.$alert.error(e.message)
        console.error("Error setAmountConfigs():",e.message)
      } finally {
        this.amountConfigsLoading = false
      }
    },
    async approveContract() {
      try {
        this.approveLoading = true
        this.approved = await contractHandler.approveContract(this.account, this.tokenAddress, this.fixedPool.address)
      } catch (e) {
        console.error(e)
        this.$alert.error('approve fail!')
      } finally {
        this.approveLoading = false
      }
    },
  },
  watch: {
    state: {
      async handler(state) {
        if (state) {
          await this.reset()
        }
      },
      immediate: true,
    },
  },
}
</script>
<style scoped>
.error-frame {
  min-height: 20px;
  color: red;
}
</style>