import alert from '@/plugins/alert'
import router from '@/router'
import api from '@/plugins/api'
import detectEthereumProvider from '@metamask/detect-provider'
import Web3 from 'web3'
import { blockchainHandler } from '@/helpers/blockchainHandler'
// import bcrypt from 'bcryptjs'

let providerListener = false
export default {
  namespaced: true,
  state: {
    user: null,
    jwt: null,
    role: {},
    isAuthenticated: false,
    provider: null,
    account: '',
    chainId: '',
    loaded: false,
    commonWeb3: null,
  },
  actions: {
    async signIn({ commit }, { identifier = '', password = '' } = {}) {
      try {
        const { jwt, user } = await api.Auth.create({ identifier, password })
        if (user.role.type !== 'admin') {
          alert.error('You have to be an Admin to signin')
          return
        }
        if (user && jwt) {
          alert.success('Login successfully!')
          commit('setUser', { user, jwt })
        }
      } catch (error) {
        console.error('Error', error)
        if (error.includes(`Identifier or password invalid`)) alert.error('Username or password is incorrect!')
        else alert.error('Login fail! please login again in some minutes')
      }
    },
    signOut({ commit }) {
      commit('reset')
      router.push('signIn')
    },
    setRole({ commit }, role) {
      commit('setRole', role)
    },

    async init({ commit, dispatch }) {
      try {
        const provider = await detectEthereumProvider()
        const chainId = await provider.request({ method: 'eth_chainId' })
        commit('changeState', { commonWeb3: window.web3 })
        if (typeof chainId !== 'undefined' && !blockchainHandler.chainIdList.includes(+chainId)) {
          alert.error('Wrong network')
        }
        commit('changeState', { provider, chainId })
        const accounts = await window.ethereum.request({ method: 'eth_accounts' })
        if (accounts.length) {
          await dispatch('connectMetamask')
        }
      } catch (err) {
        console.error(err)
      } finally {
        commit('changeState', { loaded: true })
      }
    },
    async connectMetamask({ commit, state }) {
      try {
        commit('changeState', { connectingWallet: true })
        const accounts = await state.provider.request({
          method: 'eth_requestAccounts',
        })
        if (accounts.length) {
          if (!providerListener) {
            providerListener = true
            state.provider.on('chainChanged', () => {
              window.location.reload()
            })
            state.provider.on('accountsChanged', function () {
              window.location.reload()
            })
          }

          const account = accounts[0].toLowerCase()
          commit('changeState', {
            account,
          })
        }
        commit('changeState', { connectingWallet: false })
      } catch (e) {
        console.error(e)
        alert.error(e.message)
        commit('changeState', { connectingWallet: false })
        throw e
      }
    },
    switchNetwork({ state, getters }, chainId) {
      if (getters.walletConnected) {
        state.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(chainId) }],
        })
      }
    },
  },
  mutations: {
    setUser(state, { user, jwt }) {
      state.jwt = jwt
      state.user = user
      if (user) {
        state.role = user.role
        state.isAuthenticated = true
        router.push('/fixedPools')
      } else {
        state.isAuthenticated = false
        state.role = null
      }
    },
    setRole(state, role) {
      state.role = role
    },
    reset(state) {
      state.user = null
      state.jwt = null
      state.role = {}
      state.isAuthenticated = false
    },
  },
  getters: {
    walletConnected(state) {
      return !!state.account
    },
    shortAccount(state) {
      if (!state.account) return ''
      return state.account.substr(0, 5) + '...' + state.account.substr(state.account.length - 3)
    },
  },
}
