<template>
  <v-dialog v-model="dialog" max-width="680px" persistent>
    <v-card class="border-radius-8">
      <v-card-title class="primary white--text"
        ><v-toolbar-title>Randomize Whitelist</v-toolbar-title>
        <v-spacer />
        <v-icon color="white" @click="cancel" :disabled="loading">mdi-close</v-icon>
      </v-card-title>
      <v-progress-linear :value="progress" v-if="loading"></v-progress-linear>
      <v-card-text class="pb-0" v-if="step === 1">
        <div class="warning border-radius-8 d-flex align-center justify-center pa-4 my-4">
          <div class="mr-2"><v-icon>mdi-alert</v-icon></div>
          <div>Randomize function will choose randomly accounts which are not in whitelist</div>
        </div>
        <div class="d-flex justify-center">
          <div class="mr-6">
            <div class="field-title mt-1" cols="5">Whitelisted accounts</div>
            <div class="field-number text-center" cols="7">{{ whitelistedCount }}</div>
          </div>
          <div>
            <div class="field-title mt-1" cols="5">Accounts not in whitelist</div>
            <div class="field-number text-center" cols="7">{{ unwhitelistCount }}</div>
          </div>
        </div>
        <div class="d-flex flex-column align-center mt-2">
          <div class="field-title mb-2">Number of accounts to put in whitelist</div>
          <v-text-field
            v-model="inputNum"
            type="number"
            style="max-width: 240px"
            :rules="[$rules.required]"
            outlined
            dense
          ></v-text-field>
        </div>
        <v-row class="">
          <v-col cols="6" class="field-title mt-2"></v-col>
          <v-col cols="6"> </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="step > 1">
        <v-data-table
          :items-per-page="itemsPerPage"
          :headers="headers"
          :items="randomizedWhitelists"
          hide-default-footer
        >
          <template v-slot:[`footer`]="{}">
            <div class="py-4">
              <v-pagination v-model="page" :length="totalPage" total-visible="8" circle> </v-pagination>
            </div>
          </template>
          <template v-slot:[`item.walletAddress`]="{ item }">
            {{ item.user.walletAddress | truncateAddress(10, 6) }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="px-6 py-4">
        <v-btn class="text-none elevation-0 border-radius-8" color="#757575" :loading="loading" @click="cancel" outlined
          >Cancel</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          class="text-none elevation-0 border-radius-8"
          :disabled="inputNum <= 0"
          :loading="loading"
          @click="randomizeWhitelist"
          color="primary"
          >Randomize</v-btn
        >
        <v-btn
          class="text-none elevation-0 border-radius-8"
          :loading="loading"
          :disabled="randomizedWhitelists.length === 0"
          color="primary"
          >Import</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

const originalHeaders = [
  {
    text: 'Wallet Address',
    align: 'start',
    sortable: false,
    value: 'walletAddress',
  },
  {
    text: 'Social Ticket',
    align: 'center',
    sortable: false,
    value: 'socialTicket',
  },
  {
    text: 'Retweet URL',
    align: 'start',
    sortable: false,
    value: 'retweetUrl',
  },
  {
    text: 'Referral Count',
    align: 'center',
    sortable: false,
    value: 'referralCount',
  },
]
export default {
  props: {
    state: Boolean,
    poolId: String,
  },
  data() {
    return {
      dialog: false,
      randomMode: 'All',
      page: 1,
      totalPage: 1,
      headers: originalHeaders,
      randomizedWhitelists: [],
      itemsPerPage: 10,
      loading: false,
      progress: 0,
      step: 1,
      inputNum: 0,
    }
  },
  watch: {
    state(state) {
      this.dialog = state
    },
  },
  computed: {
    ...mapState('whitelist', ['allWhitelists', 'count', 'unwhitelistCount', 'whitelistedCount', 'allWhitelists']),
  },
  methods: {
    ...mapActions('whitelist', ['fetchAllWhitelist']),
    cancel() {
      this.$emit('closeDialog')
    },
    async randomizeWhitelist() {
      this.loading = true
      this.progress = 10
      await this.fetchAllWhitelist({
        pool: this.poolId,
        isWhitelisted: false,
      })
      console.log('whitelists', this.allWhitelists)
      this.progress = 40
      const expanded = this.allWhitelists.flatMap((whitelist) => Array(Number(whitelist.socialTicket)).fill(whitelist))
      console.log('expanded', this.expanded)
      const winners = expanded[Math.floor(Math.random() * expanded.length)]
      this.randomizeWhitelists = winners
      console.log('winners', this.winners)
      this.step = 2
      this.progress = 100
      this.loading = false
    },
  },
}
</script>

<style scoped>
.warning {
  background: var(--v-primary-lighten3) !important;
}
</style>