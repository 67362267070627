import { Fixed_Pools } from '@/plugins/api'
import alert from '@/plugins/alert'
import { web3 } from '@/helpers/ContractHandler'
const json = require('@/helpers/FixedSwap.json')
import contractHandler from '@/helpers/ContractHandler.js'
import { blockchainHandler } from '@/helpers/blockchainHandler'

export default {
  namespaced: true,
  state: {
    fixedPools: [],
    fixedPool: {},
    contractInfo: {},
  },
  actions: {
    async fetchFixedPools({ commit }, { chainId, ...options }) {
      try {
        let fixedPools = await Fixed_Pools.fetch(options)
        await Promise.all(
          fixedPools.map(async (item) => {
            try {
              item.isCorrectChainId = +chainId === +item.chainId
              const commonWeb3 = item.isCorrectChainId ? web3 : blockchainHandler.getWeb3(+item.chainId)
              if (!commonWeb3) return item
              item.contract = item.address ? new commonWeb3.eth.Contract(json.abi, item.address) : null
              if (item.contract) {
                item.contractOwner = await item.contract.methods.owner().call()
                item.pausedStatus = (await contractHandler.paused(item.contract)) ? 'paused' : 'unpaused'
              }
            } catch (e) {
              console.error(e)
            }
            return item
          })
        )
        commit('setFixedPools', fixedPools)
      } catch (error) {
        alert.error(error.message)
        console.error('Error fetchFixedPools', error.message)
      }
    },
    async fetchFixedPool({ commit }, { chainId, id }) {
      try {
        const fixedPool = await Fixed_Pools.fetchOne(id)
        fixedPool.isCorrectChainId = +chainId === +fixedPool.chainId
        const commonWeb3 = fixedPool.isCorrectChainId ? web3 : blockchainHandler.getWeb3(+fixedPool.chainId)
        if (!commonWeb3) {
          commit('changeState', { fixedPool })
          return
        }
        console.log('fixedPool.isCorrectChainId', fixedPool.isCorrectChainId)
        console.log('commonWeb3', commonWeb3)
        fixedPool.contract = fixedPool.address ? new commonWeb3.eth.Contract(json.abi, fixedPool.address) : null
        if (fixedPool.contract) {
          fixedPool.contractOwner = await fixedPool.contract.methods.owner().call()
          fixedPool.pausedStatus = (await contractHandler.paused(fixedPool.contract)) ? 'paused' : 'unpaused'
        }
        commit('changeState', { fixedPool })
      } catch (error) {
        alert.error(error.message)
        console.error('Error fetchFixedPool', error.message)
      }
    },
    updatePausedStatus({ commit, state }, { id, pausedStatus }) {
      const contractInfo = { ...state.contractInfo, paused: pausedStatus }
      const fixedPools = state.fixedPools.map((item) => {
        if (item.id === id) {
          item.pausedStatus = pausedStatus
        }
        return item
      })
      commit('changeState', { contractInfo, fixedPools })
    },
    async fetchContractInfo({ commit }, contract) {
      const contractInfo = await contractHandler.getUpdateContractInfo(contract)
      commit('changeState', { contractInfo: { ...contractInfo, contract: contract } })
    },
    async createFixedPool({ commit }, { chainId, data }) {
      try {
        const fixedPool = await Fixed_Pools.create(data)
        const isCorrectChainId = +chainId === +fixedPool.chainId
        const contract = data.address ? new web3.eth.Contract(json.abi, data.address) : null
        let contractOwner, pausedStatus
        try {
          contractOwner = contract ? await contract.methods.owner().call() : ''
          pausedStatus = contract ? ((await contractHandler.paused(contract)) ? 'paused' : 'unpaused') : ''
        } catch (e) {
          console.error(e)
        } finally {
          commit('createFixedPool', { ...fixedPool, contract, contractOwner, pausedStatus, isCorrectChainId })
          alert.success('create successfully!')
        }
      } catch (e) {
        alert.error('create false')
        console.error(e)
      }
    },
    async updateFixedPool({ commit }, { chainId, data }) {
      try {
        const { id, ...fixedPool } = data
        const res = await Fixed_Pools.update(id, fixedPool)
        const contract = res.address ? new web3.eth.Contract(json.abi, res.address) : null
        const isCorrectChainId = +chainId === +res.chainId
        let contractOwner, pausedStatus
        try {
          contractOwner = contract ? await contract.methods.owner().call() : ''
          pausedStatus = contract ? ((await contractHandler.paused(contract)) ? 'paused' : 'unpaused') : ''
        } catch (e) {
          console.error(e)
        } finally {
          alert.success('update successfully!')
          commit('updateFixedPool', { ...res, contract, isCorrectChainId, contractOwner, pausedStatus })
        }
      } catch (e) {
        alert.error('update false')
        console.error(e)
      }
    },
    async removeFixedPool({ commit }, id) {
      try {
        await Fixed_Pools.remove(id)
        commit('removeFixedPool', id)
        alert.success('remove successfully!')
      } catch (e) {
        alert.error('remove fail!')
      }
    },
    setFixedPools({ commit }, pool) {
      commit('changeState', { fixedPool: pool })
    },
  },
  mutations: {
    setFixedPools(state, pools) {
      state.fixedPools = pools
    },
    updateFixedPool(state, fixedPool) {
      const fixedPools = state.fixedPools.map((p) => {
        if (p.id === fixedPool.id) return fixedPool
        else return p
      })
      state.fixedPools = fixedPools
    },
    createFixedPool(state, fixedPool) {
      state.fixedPools = [fixedPool, ...state.fixedPools]
    },
    removeFixedPool(state, id) {
      state.fixedPools = state.fixedPools.filter((p) => p.id !== id)
    },
  },
  getters: {},
}
