import axios from 'axios'
import utils from '@/plugins/utils'

const AUTH_API = '/auth/local'
const FIXED_POOLS_API = '/fixed-pools/'
const WHITELIST_API = '/whitelists/'
const PROFILE_API = '/profiles/'
const INVESTOR_API = '/investors/'
const APPLY_API = '/applies/'
const USER_API = '/users/'

const APIHelper = (api) => ({
  search: (params, option) => axios.get(api, { params }, option),
  count: (params, option) => axios.get(api + 'count', { params }, option),
  fetch: (params, option) => axios.get(api, { params: utils.filterObject(params) }, option),
  fetchOne: (id, option) => axios.get(api + id, option),
  create: (params, options) => axios.post(api, utils.filterObject(params), options),
  update: (id, params, option) => axios.put(api + id, params, option),
  remove: (id, option) => axios.delete(api + id, option),
})
export const APIRespository = APIHelper

export const User = {
  ...APIHelper(USER_API),
}
export const Auth = {
  ...APIHelper(AUTH_API),
}
export const Fixed_Pools = {
  ...APIHelper(FIXED_POOLS_API),
}
export const Investor = {
  ...APIHelper(INVESTOR_API),
  getAddressTier: (walletAddress) => axios.get(`/get-tier/${walletAddress}`, {}),
}
export const Apply = {
  ...APIHelper(APPLY_API),
}
export const Whitelist = {
  ...APIHelper(WHITELIST_API),
  checkWhitelistKYC: (poolId) =>
    axios.post('/whitelists/admin-check-kyc', {
      poolId,
    }),
}
export const Profile = {
  ...APIHelper(PROFILE_API),
  adminSignin: (account) =>
    axios.post('/admin-signin', {
      identifier: account.identifier,
      password: account.password,
    }),
}
export default {
  Auth,
  Fixed_Pools,
  Whitelist,
  Profile,
  User,
  Investor,
}
