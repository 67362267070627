import Vue from 'vue'
import routes from './routes'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  let unwatch
  unwatch = store.watch(
    (state) => state.auth.loaded,
    (loaded) => {
      if (loaded) {
        next(_authGuard(to))
        setTimeout(() => unwatch(), 1)
      }
    },
    { immediate: true }
  )
})

function _authGuard(to) {
  if (!to.name) {
    return '/fixedPools'
  }

  let hasAuthRule = false
  let requriedAuth = false
  const { isAuthenticated } = store.state.auth

  for (let route of to.matched) {
    // eslint-disable-next-line no-prototype-builtins
    if (!route.meta.hasOwnProperty('auth')) {
      continue
    }
    hasAuthRule = true
    if (route.meta.auth) {
      requriedAuth = true
      break
    }
  }

  return hasAuthRule
    ? requriedAuth
      ? isAuthenticated
        ? true
        : '/signin'
      : isAuthenticated && to.name !== 'resetPassword'
      ? '/dashboard'
      : true
    : true
}

export default router
