<template>
  <v-dialog v-model="dialog" max-width="920px" persistent>
    <v-card>
      <v-card-title class="primary white--text"
        ><v-toolbar-title>Import Whitelist</v-toolbar-title>
        <v-spacer />
        <v-icon color="white" @click="cancel" :disabled="isFileImported">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="py-6" v-if="!isImporting && !isImported">
        <div class="d-flex align-center">
          <v-text-field
            v-model="file"
            class="border-radius-8"
            style="max-width: 360px"
            prepend-inner-icon="mdi-file-excel"
            dense
            hide-details
            outlined
            disabled
          ></v-text-field>
          <v-btn
            color="primary"
            class="border-radius-8 elevation-0 text-none btn-text ml-6"
            height="40px"
            :loading="loading"
            @click="removeFile"
            v-if="file && selectedFile"
          >
            Remove file
          </v-btn>

          <v-btn
            color="primary"
            class="border-radius-8 elevation-0 text-none btn-text ml-6"
            height="40px"
            :loading="loading"
            @click="onButtonClick"
            v-else
          >
            Select file
            <input
              ref="uploader"
              type="file"
              class="d-none"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              @change="onFileChanged"
            />
          </v-btn>

          <v-radio-group v-model="roundOption">
            <v-radio label="None" value="none"></v-radio>
            <v-radio label="Tier Round" value="tierRound"></v-radio>
            <v-radio label="Community Round" value="communityRound"></v-radio>
          </v-radio-group>
        </div>
        <v-card class="elevation-0 table-border mt-8">
          <v-data-table :items-per-page="itemsPerPage" :headers="headers" :items="pagingWhitelist" hide-default-footer>
            <template v-slot:[`footer`]="{}">
              <div class="py-4">
                <v-pagination v-model="page" :length="totalPage" total-visible="8" circle> </v-pagination>
              </div>
            </template>
            <template v-slot:[`item.tierType`]="{ item }">
              {{ item.tierType }}
            </template>
            <template v-slot:[`item.walletAddress`]="{ item }">
              {{ item.walletAddress | truncateAddress(10, 6) }}
            </template>
            <template v-slot:[`item.crosschainAddress`]="{ item }">
              {{ item.crosschainAddress | truncateAddress(10, 6) }}
            </template>
            <template v-slot:[`item.isWhitelisted`]="{ item }">
              <v-icon v-if="item.isWhitelisted.toLowerCase() === 'yes'" color="success">mdi-check-circle</v-icon>
              <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
      <v-card-text class="d-flex flex-column align-center justify-center py-16" v-else-if="isImporting && !isImported">
        <v-progress-circular :rotate="360" :size="100" :width="10" :value="progress" color="primary">
          <div class="primary--text section-title">{{ progress }}%</div>
        </v-progress-circular>
        <div class="mt-6 section-title primary--text">Importing whitelist...</div>
      </v-card-text>
      <v-card-text v-else>
        <div style="height: 520px; overflow-y: scroll" v-if="invalidWhitelists && invalidWhitelists.length > 0">
          <div class="text-h6 my-4">Errors occured when import whitelist:</div>
          <v-divider></v-divider>
          <div v-for="(error, index) in invalidWhitelists" :key="index">
            <div class="field-title error--text py-2">
              {{ index + 1 }} - Wallet Address {{ error.walletAddress }} - Error: {{ error.message }}
            </div>
            <v-divider></v-divider>
          </div>
        </div>
        <div class="d-flex justify-center align-center my-16 px-6" v-else>
          <div class="text-h5 font-weight-bold success--text text-center">
            Import successfully without errors. Please close this dialog and check carefully!
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex align-center px-6 pb-4">
        <v-btn
          class="border-radius-8 text-none btn-text"
          color="#9298AB"
          @click="cancel"
          :disabled="isFileImported"
          outlined
          >Cancel</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          class="border-radius-8 elevation-0 text-none btn-text px-4"
          color="primary"
          v-if="isFileImported && !isImported && !isImporting"
          @click="importWhitelists"
          >Import</v-btn
        >
        <v-btn
          class="border-radius-8 elevation-0 text-none btn-text px-4"
          color="primary"
          v-else-if="isFileImported && isImported && !isImporting"
          @click="doneImport()"
          >Done</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const schema = {
  'Wallet Address': {
    prop: 'walletAddress',
    type: String,
  },
  Tier: {
    prop: 'tier',
    type: String,
  },
  Id: {
    prop: 'id',
    type: String,
  },
  'Social Ticket': {
    prop: 'socialTicket',
    type: Number,
  },
  'Reference Count': {
    prop: 'referenceCount',
    type: Number,
  },
  'Total Ticket': {
    prop: 'totalTicket',
    type: Number,
  },
  'Is Whitelisted': {
    prop: 'isWhitelisted',
    type: String,
  },
}
const originalHeaders = [
  {
    text: 'Wallet Address',
    align: 'start',
    sortable: false,
    value: 'walletAddress',
  },
  {
    text: 'Tier',
    align: 'start',
    sortable: false,
    value: 'tier',
  },
  {
    text: 'Social Ticket',
    align: 'center',
    sortable: false,
    value: 'socialTicket',
  },
  {
    text: 'Reference Count',
    align: 'center',
    sortable: false,
    value: 'referenceCount',
  },
  {
    text: 'Total Ticket',
    align: 'center',
    sortable: false,
    value: 'totalTicket',
  },
  {
    text: 'Is Whitelisted',
    align: 'center',
    sortable: false,
    value: 'isWhitelisted',
  },
]
import readXlsxFile from 'read-excel-file'
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    state: Boolean,
  },
  data() {
    return {
      dialog: false,
      selectedFile: null,
      file: null,
      whitelists: [],
      itemsPerPage: 10,
      headers: originalHeaders,
      page: 1,
      totalPage: 1,
      loading: false,
      invalidWhitelists: [],
      isImporting: false,
      isImported: false,
      roundOption: 'none',
    }
  },
  watch: {
    state(state) {
      this.dialog = state
      if (state) {
        this.roundOption = 'none'
      }
    },
  },
  computed: {
    ...mapState('apply', ['progress']),
    pagingWhitelist() {
      return this.whitelists.slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage)
    },
    isFileImported() {
      return this.file && this.whitelists && this.whitelists.length > 0
    },
  },
  methods: {
    ...mapActions('apply', ['updateApplies']),
    cancel() {
      this.removeFile()
      this.$emit('closeDialog')
    },
    removeFile() {
      this.selectedFile = null
      this.file = null
      this.whitelists = []
      this.invalidWhitelists = []
      this.isImported = false
      this.isImporting = false
    },
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      this.$refs.uploader.click()
    },
    doneImport() {
      this.removeFile()
      this.$emit('closeDialog')
      this.$emit('doneImport')
    },
    async onFileChanged(e) {
      this.$loading.active = true
      this.selectedFile = e.target.files[0]
      let readFileResult
      try {
        readFileResult = await readXlsxFile(this.selectedFile, {
          schema,
        })
      } catch (error) {
        this.$alert.error('File extension is not .xlsx or file data is not correct!')
        this.$loading.active = false
        return
      }
      if (readFileResult.errors.length !== 0) {
        const error = readFileResult.errors[0]
        this.$alert.error(`Error row ${error.row} - column ${error.column} - ${error.value}: ${error.error}`)
        this.$loading.active = false
        return
      } else {
        this.file = this.selectedFile.name
        this.$alert.success('Read Excel file successfully!')
        this.whitelists = readFileResult.rows
        this.whitelists = this.whitelists.filter((whitelist) => whitelist?.isWhitelisted?.toLowerCase() === 'yes')
        this.totalPage = Math.ceil(this.whitelists.length / this.itemsPerPage)
      }
      this.$loading.active = false
    },
    async importWhitelists() {
      this.isImporting = true
      this.invalidWhitelists = await this.updateApplies({ applies: this.whitelists, roundOption: this.roundOption })
      this.isImporting = false
      this.isImported = true
    },
  },
}
</script>
