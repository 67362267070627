import Vue from 'vue'
import Vuex from 'vuex'
import { createStore } from 'vuex-extensions'
import createPersistedState from 'vuex-persistedstate'
import auth from './app/auth'
import apply from './apply'
import user from './user'
import fixedPools from './fixedPools'
import { axiosPlugin } from '@/plugins/axios'

Vue.use(Vuex)
import _ from 'lodash'

export default createStore(Vuex.Store, {
  plugins: [
    createPersistedState({
      key: 'enrollment',
      paths: ['auth.user', 'auth.isAuthenticated', 'auth.jwt', 'auth.role'],
    }),
    axiosPlugin,
  ],
  state: {},
  modules: {
    auth,
    fixedPools,
    apply,
    user,
  },
  mutations: {},
  actions: {},
  mixins: {
    mutations: {
      changeState: function (state, changed) {
        Object.entries(changed).forEach(([name, value]) => {
          state[name] = value
        })
      },
      changeDeepState: function (state, changed) {
        Object.entries(changed).forEach(([firstChildName, diff]) => {
          const firstChildValue = { ...state[firstChildName] }
          Object.entries(diff).forEach(([path, diffValue]) => {
            _.setWith(firstChildValue, path, diffValue)
          })
          state[firstChildName] = firstChildValue
        })
      },
    },
  },
})
