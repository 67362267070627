<template>
  <div>
    <div class="d-flex align-center justify-space-between my-6">
      <div class="text-h5 font-weight-bold">All Projects ({{ fixedPools.length }})</div>
      <div class="d-flex justify-end">
        <v-btn rounded class="text-none elevation-0" color="primary" @click="addPoolState = !addPoolState"
          ><v-icon class="mr-2">mdi-plus</v-icon> Create new project</v-btn
        >
      </div>
    </div>
    <v-data-table :headers="headers" :items="fixedPools">
      <template v-slot:[`item.action`]="{ item }">
        <PoolListActions :item="item" />
      </template>
      <template v-slot:[`item.startDate`]="{ item }">
        {{ item.startDate | mmddyyyyhhmm }}
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div class="d-flex align-center">
          <ChainLogo :chain="getChain(item)" height="15" />
          <span class="ml-2">{{ item.name }}</span>
        </div>
      </template>
      <template v-slot:[`item.ratio`]="{ item }">
        {{ `1 ${item.tokenName} = ${item.ratio} ${item.tradeToken}` }}
      </template>
      <template v-slot:[`item.pausedStatus`]="{ item }">
        <v-chip :color="getPausedStatusColor(item.pausedStatus)" small>
          {{ item.pausedStatus || 'waiting deploy' }}</v-chip
        >
      </template>
      <template v-slot:[`item.contractOwner`]="{ item }">
        <span :class="getConnectedState(item) ? 'success' : ''">
          {{ item.contractOwner
          }}{{ item.contractOwner ? `(${getConnectedState(item) ? 'connected' : 'disconnected'})` : '' }}
        </span>
      </template>
    </v-data-table>
    <FixedPoolAddDialog :state="addPoolState" />
  </div>
</template>
<script>
const originalHeader = [
  {
    text: 'Project Name',
    align: 'start',
    sortable: false,
    value: 'name',
  },
  {
    text: 'Index ',
    align: 'start',
    sortable: true,
    value: 'index',
    width: 100,
  },
  {
    text: 'Launch Date',
    align: 'start',
    sortable: false,
    value: 'startDate',
  },
  {
    text: 'Sell Price',
    align: 'start',
    sortable: false,
    value: 'ratio',
  },
  {
    text: 'Status',
    align: 'start',
    sortable: false,
    value: 'pausedStatus',
  },
  {
    align: 'start',
    sortable: false,
    value: 'contractOwner',
    text: 'Creator Address',
  },
  {
    align: 'end',
    sortable: false,
    value: 'action',
  },
]
import PoolListActions from '@/modules/fixedPools/PoolListActions'
import FixedPoolAddDialog from '@/modules/fixedPools/dialogs/FixedPoolAddDialog'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    PoolListActions,
    FixedPoolAddDialog,
  },
  data() {
    return {
      headers: originalHeader,
      addPoolState: false,
    }
  },
  methods: {
    ...mapActions('fixedPools', ['fetchFixedPools']),
    getConnectStatusColor(isCorrectChainId) {
      return isCorrectChainId ? 'success' : 'error'
    },
    getPausedStatusColor(pausedStatus) {
      return pausedStatus === 'paused' ? 'error' : pausedStatus === 'unpaused' ? 'success' : 'warning'
    },
    getConnectedState(item) {
      return item.contractOwner && item.contractOwner.toLowerCase() === this.account && item.isCorrectChainId
    },
    getChain(item) {
      switch (item.chainId) {
        case 1:
        case 3:
          return 'eth'
        case 97:
        case 56:
          return 'bsc'
        default:
          return 'bsc'
      }
    },
  },
  computed: {
    ...mapState('fixedPools', ['fixedPools']),
    ...mapState('auth', ['chainId', 'account']),
  },
  async created() {
    this.$loading.active = true
    await this.fetchFixedPools({ chainId: this.chainId })
    this.$loading.active = false
  },
}
</script>