<template>
  <v-dialog v-model="dialog" max-width="950px" :fullscreen="$vuetify.breakpoint.smAndDown">
    <v-card>
      <v-card-title class="primary white--text"
        ><v-toolbar-title>Update contract</v-toolbar-title>
        <v-spacer />
        <v-icon color="white" @click="dialog = false">mdi-close</v-icon>
      </v-card-title>

      <v-card-text class="pa-6 pt-2">
        <div class="text-h5 mb-2 black--text">Your project: {{ fixedPool.name }}</div>
        <ContractUpdateForm
          ref="contractUpdateForm"
          :fixedPool="fixedPool"
          :contractInfo="contractInfo"
          :state="dialog"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import ContractUpdateForm from '../forms/ContractUpdateForm.vue'
export default {
  props: {
    state: Boolean,
    fixedPool: {
      default: () => {},
      type: Object,
    },
  },
  components: {
    ContractUpdateForm,
  },
  data() {
    return {
      dialog: false,
      contractInfo: null,
    }
  },
  methods: {
    ...mapActions('fixedPools', ['fetchFixedPools']),
  },
  computed: {
    ...mapState('fixedPools', ['fixedPools']),
  },
  async created() {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    state(state) {
      this.dialog = true
    },
  },
}
</script>
