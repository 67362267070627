<template>
  <v-form ref="form" class="pa-3">
    <div class="text-h5 mb-6">Redeem Configs</div>
    <v-row v-for="(item, index) in redeemConfigs" :key="index">
      <v-col cols="12" class="py-0 mb-2">
        <v-sheet outlined color="primary" rounded style="position: relative">
          <v-icon
            style="position: absolute; top: 0; right: 0; z-index: 100"
            :disabled="index < originalLength || index === 0"
            @click="removeRow(index)"
            >mdi-close</v-icon
          >
          <v-card outlined elevation="0" class="pa-3 pb-1">
            <v-row class="pa-0">
              <v-col cols="1" class="pb-0">
                <div class="d-flex align-center">Id-{{ index }}</div>
              </v-col>
              <v-col cols="6" class="pb-0">
                <v-text-field
                  class="required"
                  :rules="[$rules.required, $rules.positiveInterger]"
                  v-model="item.redeemDate"
                  label="Redeem date"
                  dense
                  outlined
                  required
                />
              </v-col>
              <v-col cols="5" class="py-0">
                <div>
                  <v-datetime-picker
                    v-model="item.pickerTime"
                    outline
                    label="Select Datetime"
                    @input="pickerChange(item)"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar-range</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock-time-three</v-icon>
                    </template>
                  </v-datetime-picker>
                  <div>
                    Local Time: <span class="red--text">{{ item && item.redeemDate | timestampToLocalTime }}</span>
                  </div>
                  <div>
                    GMT Time : <span class="red--text">{{ item && item.redeemDate | timestampToGMTTime }}</span>
                  </div>
                </div>
              </v-col>
              <v-col cols="1" class="py-0" />
              <v-col cols="6" class="py-0">
                <v-text-field
                  class="required"
                  :rules="[$rules.required, $rules.positiveNumber]"
                  v-model="item.redeemPercent"
                  label="Redeem percent"
                  dense
                  outlined
                  required
                />
              </v-col>
              <v-col cols="5" class="py-0">
                <v-switch
                  v-model="item.allowUpdate"
                  v-if="index < originalLength"
                  :label="`Allow update: ${item.allowUpdate.toString()}`"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
    <div class="d-flex d-flex justify-space-between mt-3">
      <v-btn small class="primary rounded-lg text-capitalize" :loading="redeemConfigsLoading" @click="setRedeemConfigs"
        >Change redeem config</v-btn
      >
      <v-btn small @click="addRow" class="dark-gray rounded-lg"><v-icon>mdi-plus</v-icon> Add row</v-btn>
    </div>
  </v-form>
</template>
<script>
import moment from 'moment'
import contractHandler from '@/helpers/ContractHandler'
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    state: Boolean,
  },
  components: {},
  data() {
    return {
      redeemConfigs: [{ redeemDate: '0', redeemPercent: '0', allowUpdate: true, id: -1, pickerTime: '' }],
      datetime: '',
      redeemConfigsLoading: false,
      originalLength: 0,
    }
  },
  computed: {
    ...mapState('fixedPools', ['contractInfo']),
    ...mapState('auth', ['account']),
  },
  methods: {
    ...mapActions('auth', ['connectMetamask']),
    removeRow(index) {
      if (this.redeemConfigs.length > 1) this.redeemConfigs.splice(index, 1)
    },
    addRow() {
      this.redeemConfigs.push({ redeemDate: '0', redeemPercent: '0', allowUpdate: true, id: -1, pickerTime: '' })
    },
    validate() {
      return this.$refs.form.validate()
    },
    pickerChange(item) {
      item.redeemDate = Date.parse(item.pickerTime) / 1000
    },
    getRedeemConfigs() {
      if (!this.$refs.form.validate()) return null
      return this.redeemConfigs
    },
    reset() {
      this.$refs.form && this.$refs.form.resetValidation()
      this.originalLength = !this.contractInfo.redeemConfigs ? 0 : this.contractInfo.redeemConfigs.length
      if (this.originalLength === 0) {
        this.redeemConfigs = [{ redeemDate: '0', redeemPercent: '0', allowUpdate: true, id: -1, pickerTime: '' }]
      } else {
        this.redeemConfigs = this.contractInfo.redeemConfigs
      }
    },
    async setRedeemConfigs() {
      if (!this.$refs.form.validate()) return
      try {
        this.redeemConfigsLoading = true
        this.redeemConfigs.map((item, index) => {
          item.id = index >= this.originalLength ? index : item.id
          return item
        })
        const redeemConfigs = this.redeemConfigs.filter((item) => item.allowUpdate === true)
        await contractHandler.setRedeemConfigs(this.contractInfo.contract, redeemConfigs, this.account)
        this.redeemConfigs.map((item, index) => {
          item.allowUpdate = true
          item.id = index
          return item
        })
        this.contractInfo.redeemConfigs = this.redeemConfigs
        this.$store.commit('fixedPools/changeState', { contractInfo: this.contractInfo })
        this.originalLength = this.redeemConfigs.length
        this.$alert.success('Update RedeemConfig success!')
      } catch (e) {
        this.$alert.error(e.message)
        console.error("setRedeemConfigs() error: ", e.message)
      } finally {
        this.redeemConfigsLoading = false
      }
    },
  },
  filters: {
    timestampToLocalTime(time) {
      return moment(time * 1000)
        .local()
        .format('YYYY-MM-DD HH:mm')
    },
    timestampToGMTTime(time) {
      return moment(time * 1000)
        .utc()
        .format('YYYY-MM-DD HH:mm')
    },
  },
  watch: {
    state: {
      handler(state) {
        if (state) {
          this.reset()
        }
      },
      immediate: true,
    },
  },
}
</script>