<template>
  <v-form ref="form">
    <v-row>
      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required]"
          v-model="name"
          label="Project Name"
          dense
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          v-model="address"
          dense
          label="Contract address"
          outlined
          :rules="[rules.contractAddress]"
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required, $rules.positiveNumber]"
          v-model="ratio"
          dense
          label="Ratio"
          outlined
          required
          :hint="`ratio = token/ tradeToken, 1 token= ${ratio} USDT`"
          persistent-hint
        ></v-text-field>
      </v-col>
       <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          label="Type"
          :rules="[$rules.required]"
          v-model="type"
          dense
          outlined
          required
        ></v-text-field>
      </v-col>
       <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required, $rules.validTax]"
          v-model="userTax"
          dense
          label="UserTax"
          outlined
          required
        ></v-text-field>
      </v-col> 
      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required, $rules.validTax]"
          v-model="sellerTax"
          dense
          label="SellerTax"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          label="Seller address"
          v-model="ownerAddress"
          :rules="[$rules.required]"
          required
          class="required"
          dense
          outlined
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          label="Index"
          v-model="index"
          :rules="[$rules.positiveInterger, $rules.required]"
          dense
          class="required"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-0 d-flex" cols="12">
        <v-text-field
          class="required mt-0 col-md-8"
          label="Access Type"
          :rules="[$rules.required]"
          v-model="accessType"
          dense
          outlined
          required
        ></v-text-field>
        <v-radio-group class="mt-0 ml-4" row dense @change="accessType = $event" v-model="accessType">
          <v-radio v-for="item in accessTypeList" :key="item" :label="item" :value="item"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col class="py-0 d-flex" cols="12">
        <v-text-field
          class="required mt-0 col-md-8"
          label="ChainId"
          :rules="[$rules.required]"
          v-model="chainId"
          dense
          outlined
          required
        ></v-text-field>
        <v-radio-group class="mt-0 ml-4" row dense @change="chainId = $event" v-model="chainId">
          <v-radio v-for="item in chainIdList" :key="item" :label="item" :value="item"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col class="py-0 d-flex" cols="12">
        <v-text-field
          class="required mt-0 col-md-8"
          label="Chain Network"
          :rules="[$rules.required]"
          v-model="chain"
          dense
          outlined
          required
        ></v-text-field>
        <v-radio-group class="mt-0 ml-4" row dense @change="chain = $event" v-model="chain">
          <v-radio v-for="item in chainList" :key="item" :label="item" :value="item"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col class="py-0" cols="6">
        <div class="d-flex">
          <v-text-field
            type="date"
            class="required mr-2"
            :rules="[$rules.required]"
            v-model="startDate"
            label="Start date"
            dense
            outlined
            required
          ></v-text-field>
          <v-text-field
            type="time"
            class="required"
            :rules="[$rules.required]"
            v-model="startHour"
            label="Hour"
            dense
            outlined
            required
          ></v-text-field>
        </div>
      </v-col>
      <v-col class="py-0" cols="6">
        <div>
          <div>
            Local Time: <span class="red--text">{{ getLocalStartDate }}</span>
          </div>
          <div>
            GMT Time : <span class="red--text">{{ getGMTStartDate }}</span>
          </div>
        </div>
      </v-col>
      <v-col class="py-0" cols="6">
        <div class="d-flex">
          <v-text-field
            type="date"
            class="required mr-2"
            :rules="[$rules.required]"
            v-model="endDate"
            label="End date"
            dense
            outlined
            required
          ></v-text-field>
          <v-text-field
            type="time"
            class="required"
            :rules="[$rules.required]"
            v-model="endHour"
            label="Hour"
            dense
            outlined
            required
          ></v-text-field>
        </div>
      </v-col>

      <v-col class="py-0" cols="6">
        <div>
          <div>
            Local Time: <span class="red--text">{{ getLocalEndDate }}</span>
          </div>
          <div>
            GMT Time : <span class="red--text">{{ getGMTEndDate }}</span>
          </div>
        </div>
      </v-col>

      <v-col class="py-0" cols="12">
        <v-text-field label="LogoUrl" v-model="logoUrl" dense outlined></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          label="MinAllocation"
          :rules="[$rules.required, $rules.positiveNumber]"
          v-model="minAllocation"
          dense
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          label="MaxAllocation"
          :rules="[$rules.required, $rules.positiveNumber]"
          v-model="maxAllocation"
          dense
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          label="TotalRaise"
          :rules="[$rules.positiveNumber]"
          v-model="totalRaise"
          dense
          outlined
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          label="TotalSupply"
          :rules="[$rules.positiveNumber]"
          v-model="totalSupply"
          dense
          outlined
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="12">
        <v-textarea label="Description" v-model="description" dense outlined></v-textarea>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import moment from 'moment'
import Web3 from 'web3'
export default {
  props: {
    fixedPool: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      name: '',
      address: '',
      ratio: '',
      userTax: '5',
      sellerTax: '5',
      accessType: 'Whitelist',
      accessTypeList: ['Whitelist', 'WAG'],
      type: 'v4',
      startDate: '',
      endDate: '',
      startHour: '00:00',
      endHour: '00:00',
      index: '',
      ownerAddress: '',
      chain: 'bsc',
      chainList: ['bsc', 'eth'],
      chainId: '56',
      chainIdList: ['1', '3', '56', '97'],
      logoUrl: '',
      minAllocation: 'TBA',
      maxAllocation: 'TBA',
      totalRaise: '',
      totalSupply: '',
      description: '',
      rules: {
        contractAddress: (v) => {
          return !v || Web3.utils.isAddress(v) || 'invalid address'
        },
      },
    }
  },
  computed: {
    getLocalStartDate() {
      return moment(`${this.startDate} ${this.startHour}`, 'YYYY-MM-DD HH:mm').local().format('YYYY-MM-DD HH:mm')
    },
    getGMTStartDate() {
      return moment(`${this.startDate} ${this.startHour}`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
    },
    getLocalEndDate() {
      return moment(`${this.endDate} ${this.endHour}`, 'YYYY-MM-DD HH:mm').local().format('YYYY-MM-DD HH:mm')
    },
    getGMTEndDate() {
      return moment(`${this.endDate} ${this.endHour}`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
    },
  },
  methods: {
    getData() {
      if (!this.$refs.form.validate()) return null
      const startDate = moment(`${this.startDate} ${this.startHour}`, 'YYYY-MM-DD HH:mm').toISOString()
      const endDate = moment(`${this.endDate} ${this.endHour}`, 'YYYY-MM-DD HH:mm').toISOString()
      const data = {
        name: this.name,
        address: this.address,
        ratio: this.ratio,
        sellerTax: this.sellerTax,
        userTax: this.userTax,
        accessType: this.accessType,
        type: this.type,
        startDate,
        endDate,
        index: this.index,
        ownerAddress: this.ownerAddress && this.ownerAddress.toLowerCase(),
        chain: this.chain,
        chainId: this.chainId,
        logoUrl: this.logoUrl,
        minAllocation: this.minAllocation,
        maxAllocation: this.maxAllocation,
        totalRaise: this.totalRaise,
        totalSupply: this.totalSupply,
      }
      return data
    },
    reset() {
      this.$refs.form && this.$refs.form.resetValidation()
      if (!this.fixedPool) {
        this.resetDefault()
      } else {
        this.name = this.fixedPool.name
        this.address = this.fixedPool.address
        this.ratio = this.fixedPool.ratio
        this.sellerTax= this.fixedPool.sellerTax
        this.userTax= this.fixedPool.userTax,
        this.accessType = `${this.fixedPool.accessType}`
        this.type = this.fixedPool.type
        this.startDate = this.fixedPool.startDate ? moment(this.fixedPool.startDate).format('YYYY-MM-DD') : ''
        this.endDate = this.fixedPool.endDate ? moment(this.fixedPool.endDate).format('YYYY-MM-DD') : ''
        this.startHour = this.fixedPool.startDate ? moment(this.fixedPool.startDate).format('HH:mm') : ''
        this.endHour = this.fixedPool.endDate ? moment(this.fixedPool.endDate).format('HH:mm') : ''
        this.index = this.fixedPool.index
        this.ownerAddress = this.fixedPool.ownerAddress
        this.chain = this.fixedPool.chain
        this.chainId = `${this.fixedPool.chainId}`
        this.logoUrl = this.fixedPool.logoUrl
        this.minAllocation = this.fixedPool.minAllocation
        this.maxAllocation = this.fixedPool.maxAllocation
        this.totalRaise = this.fixedPool.totalRaise
        this.totalSupply = this.fixedPool.totalSupply
      }
    },
    resetDefault() {
      this.name = ''
      this.address = ''
      this.ratio = ''
      this.userTax = '5'
      this.sellerTax = '5'
      this.accessType = 'Whitelist'
      this.type = 'v4'
      this.startDate = ''
      this.endDate = ''
      this.startHour = '00:00'
      this.endHour = '00:00'
      this.index = ''
      this.ownerAddress = ''
      this.chainId = '56'
      this.chain = 'bsc'
      this.logoUrl = ''
      this.minAllocation = 'TBA'
      this.maxAllocation = 'TBA'
      this.totalRaise = ''
      this.totalSupply = ''
    },
  },
  created() {
    this.reset()
  },
}
</script>
