<template>
  <v-form ref="form">
    <v-row>
      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required]"
          v-model="tokenName"
          label="Token Name"
          dense
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required]"
          v-model="tokenAddress"
          dense
          label="Token address"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required]"
          v-model="tradeToken"
          dense
          label="Trade Token"
          outlined
          required
        ></v-text-field>
      </v-col>

      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required]"
          v-model="tradeTokenAddress"
          dense
          label="Trade Token Address"
          outlined
          required
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
export default {
  props: {
    fixedPool: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      tokenName: '',
      tokenAddress: '0xa59800c67d1654a2f950bb05f9d7ff21b0a1d515',
      tradeToken: 'BUSD',
      tradeTokenAddress: process.env.VUE_APP_TRADE_TOKEN_ADDRESS,
    }
  },
  methods: {
    getData() {
      if (!this.$refs.form.validate()) return null
      const data = {
        tokenName: this.tokenName,
        tokenAddress: this.tokenAddress,
        tradeToken: this.tradeToken,
        tradeTokenAddress: this.tradeTokenAddress,
      }
      return data
    },
    reset() {
      this.$refs.form && this.$refs.form.resetValidation()
      if (!this.fixedPool) {
        this.resetDefault()
      } else {
        this.tokenName = this.fixedPool.tokenName
        this.tokenAddress = this.fixedPool.tokenAddress
        this.tradeToken = this.fixedPool.tradeToken
        this.tradeTokenAddress = `${this.fixedPool.tradeTokenAddress}`
      }
    },
    resetDefault() {
      this.tokenName = ''
      this.tokenAddress = '0xa59800c67d1654a2f950bb05f9d7ff21b0a1d515'
      this.tradeToken = 'BUSD'
      this.tradeTokenAddress = process.env.VUE_APP_TRADE_TOKEN_ADDRESS
    },
  },
  created() {
    this.reset()
  },
}
</script>
