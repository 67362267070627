<template>
  <v-dialog v-model="dialog" max-width="950px" :fullscreen="$vuetify.breakpoint.smAndDown">
    <v-card>
      <v-card-title class="primary white--text"
        ><v-toolbar-title>Edit Project</v-toolbar-title>
        <v-spacer />
        <v-icon color="white" @click="dialog = false">mdi-close</v-icon>
      </v-card-title>

      <v-card-text class="pa-6 py-2">
        <div class="text-h5 mb-4 black--text">Project Info</div>
        <FixedPoolForm ref="fixedPoolForm" :fixedPool="fixedPool" />
        <div class="text-h5 mb-4 black--text">Token Info</div>
        <TokenInfoForm ref="tokenInfoForm" :fixedPool="fixedPool" />
        <div class="text-h5 mb-4 black--text">Data</div>
        <FixedPoolDataForm ref="fixedPoolDataForm" :fixedPool="fixedPool" />
      </v-card-text>
      <v-divider class="mb-6"></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed color="primary" class="mr-4 mt-n4 mb-2 white--text rounded-lg" @click="update"
          ><v-icon color="white" left> mdi-pencil-outline </v-icon>Update Project</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import FixedPoolForm from '../forms/FixedPoolForm.vue'
import FixedPoolDataForm from '../forms/FixedPoolDataForm.vue'
import TokenInfoForm from '../forms/TokenInfoForm.vue'
export default {
  props: {
    state: Boolean,
    fixedPool: {
      default: () => {},
      type: Object,
    },
  },
  components: {
    FixedPoolForm,
    TokenInfoForm,
    FixedPoolDataForm,
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    ...mapState('auth', ['chainId']),
  },
  methods: {
    ...mapActions('fixedPools', ['updateFixedPool']),
    async update() {
      const fixedPoolForm = this.$refs.fixedPoolForm.getData()
      const tokenInfoForm = this.$refs.tokenInfoForm.getData()
      const fixedPoolDataForm = this.$refs.fixedPoolDataForm.getData()
      if (!fixedPoolForm || !tokenInfoForm || !fixedPoolDataForm) return

      this.$dialog.confirm({
        title: 'Edit Project',
        text: 'Are you sure update this project ?',
        okText: 'Yes',
        cancelText: 'No',
        done: async () => {
          this.$loading.active = true
          const data = {
            id: this.fixedPool.id,
            ...fixedPoolForm,
            ...tokenInfoForm,
            data: { ...fixedPoolDataForm },
          }
          await this.updateFixedPool({ data, chainId: this.chainId })
          this.$loading.active = false
          this.dialog = false
        },
      })
    },
    resetDefault() {
      this.$refs.fixedPoolForm && this.$refs.fixedPoolForm.reset()
      this.$refs.tokenInfoForm && this.$refs.tokenInfoForm.reset()
      this.$refs.fixedPoolDataForm && this.$refs.fixedPoolDataForm.reset()
    },
  },
  async created() {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    state(state) {
      this.dialog = true
      this.resetDefault()
    },
  },
}
</script>
