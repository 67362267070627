<template>
  <v-dialog v-model="dialog" max-width="950px" :fullscreen="$vuetify.breakpoint.smAndDown">
    <v-card>
      <v-card-title class="primary white--text"
        ><v-toolbar-title>Deploy Project</v-toolbar-title>
        <v-spacer />
        <v-icon color="white" @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pa-6 pt-3">
        <div class="text-h5 mb-6 black--text">Project Info</div>
        <FixedPoolDeployForm ref="fixedPoolDeployForm" :fixedPool="fixedPool" />
      </v-card-text>
      <v-divider class="ma-3"></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed color="primary" class="mr-4 mb-2 white--text rounded-lg" @click="deploy"
          ><v-icon color="white" left> mdi-rocket-launch-outline </v-icon>Deploy</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import FixedPoolDeployForm from '../forms/FixedPoolDeployForm.vue'
import contractHandler from '@/helpers/ContractHandler.js'
export default {
  components: {
    FixedPoolDeployForm,
  },
  props: {
    state: Boolean,
    fixedPool: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    ...mapState('auth', ['account', 'chainId']),
  },
  methods: {
    ...mapActions('auth', ['connectMetamask']),
    ...mapActions('fixedPools', ['updateFixedPool']),
    resetDefault() {
      this.$refs.fixedPoolDeployForm && this.$refs.fixedPoolDeployForm.reset()
    },
    async deploy() {
      const data = this.$refs.fixedPoolDeployForm.getData()
      if (!data) return
      try {
        this.$loading.active = true
        if (!this.account) await this.connectMetamask()
        if (!this.account) return
        const res = await contractHandler.deployContract(this.account, data)
        await this.updateFixedPool({
          data: { id: this.fixedPool.id, address: res.contractAddress },
          chainId: this.chainId,
        })
        this.dialog = false
      } catch (e) {
        this.$alert.error(e.message)
        console.error("Error deploy():",e.message)
      } finally {
        this.$loading.active = false
      }
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    state(state) {
      this.dialog = true
      this.resetDefault()
    },
  },
}
</script>
