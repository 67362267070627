<template>
  <v-dialog v-model="dialog.state" max-width="480px">
    <v-card class="pb-2">
      <v-card-title class="text-subtitle-1 text-uppercase font-weight-bold primary white--text"
        >{{ dialog.title }}<v-spacer /> <v-icon color="white" @click="cancel">mdi-close</v-icon></v-card-title
      >
      <v-divider />
      <v-card-text class="mt-4" style="width: 450px">
        <div class="d-flex justify-space-around align-center mt-4">
          <v-icon class="mr-4" color="orange" x-large>mdi-alert</v-icon>
          <div v-if="dialog.text" v-html="dialog.text"></div>
        </div>
        <slot></slot>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-spacer></v-spacer>
        <v-btn
          v-show="!dialog.hideCancel"
          color="primary"
          class="text-none ma-0 mr-4 px-3 border-radius-8"
          @click="cancel"
          depressed
          text
          outlined
          >{{ dialog.cancelText || 'Cancel' }}</v-btn
        >
        <v-btn
          v-show="!dialog.hideOk"
          @click="done"
          class="text-none ma-0 px-5 border-radius-8"
          depressed
          color="primary"
          >{{ dialog.okText || 'Confirm' }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: this.$dialog.confirmData,
    }
  },
  methods: {
    done() {
      this.dialog.done()
      this.cancel()
    },
    cancel() {
      this.$dialog.cancel()
    },
  },
}
</script>
