<template>
  <v-form ref="form">
    <v-row class="pb-0">
      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required]"
          v-model="tokenAddress"
          label="Token address"
          dense
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required]"
          v-model="ownerAddress"
          dense
          label="Seller address"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required]"
          v-model="tradeTokenAddress"
          dense
          label="Token trade address"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required]"
          v-model="farmAddress"
          dense
          label="Farm contract address"
          outlined
          required
        ></v-text-field>
      </v-col>

      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required]"
          v-model="tradeValue"
          dense
          label="Trade value"
          :hint="`ratio = ${fixedPool.tokenName}/ ${fixedPool.tradeToken}, 1 ${fixedPool.tokenName}= ${tradeValue} USDT`"
          persistent-hint
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required]"
          v-model="tokensForSale"
          dense
          label="Token for sale"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required, $rules.validTax]"
          v-model="userTax"
          dense
          label="UserTax"
          outlined
          required
        ></v-text-field>
      </v-col> 
      <v-col class="py-0" cols="6">
        <v-text-field
          class="required"
          :rules="[$rules.required, $rules.validTax]"
          v-model="sellerTax"
          dense
          label="SellerTax"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-switch v-model="hasWhiteListing" :label="`Has WhiteListing: ${hasWhiteListing.toString()}`" dense></v-switch>
      </v-col>
      <v-col cols="6" class="pt-0"/>
      <v-col cols="6" class="pb-0">
        <v-text-field
          class="required"
          :rules="[
            $rules.required,
            $rules.positiveInterger,
            $rules.timestampGtCurrentTime,
            $rules.timestampLimit,
            rules.date,
          ]"
          v-model="startDate"
          label="StartDate"
          dense
          outlined
          required
        />
      </v-col>
      <v-col cols="6" class="pt-0">
        <div>
          <v-datetime-picker
            v-model="startDatePicker"
            outline
            label="Select Datetime"
            @input="startDate = Date.parse(startDatePicker) / 1000"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar-range</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock-time-three</v-icon>
            </template>
          </v-datetime-picker>
          <div>
            Local Time: <span class="red--text">{{ this.startDate | timestampToLocalTime }}</span>
          </div>
          <div>
            GMT Time : <span class="red--text">{{ this.startDate | timestampToGMTTime }}</span>
          </div>
        </div>
      </v-col>
      <v-col cols="6" class="pb-0">
        <v-text-field
          class="required"
          :rules="[
            $rules.required,
            $rules.positiveInterger,
            $rules.timestampGtCurrentTime,
            $rules.timestampLimit,
            rules.date,
          ]"
          v-model="endDate"
          label="endDate"
          dense
          outlined
          required
        />
      </v-col>
      <v-col cols="6" class="py-0">
        <div>
          <v-datetime-picker
            v-model="endDatePicker"
            outline
            label="Select Datetime"
            @input="endDate = Date.parse(endDatePicker) / 1000"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar-range</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock-time-three</v-icon>
            </template>
          </v-datetime-picker>
          <div>
            Local Time: <span class="red--text">{{ this.endDate | timestampToLocalTime }}</span>
          </div>
          <div>
            GMT Time : <span class="red--text">{{ this.endDate | timestampToGMTTime }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import moment from 'moment'
export default {
  props: {
    fixedPool: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      tokenAddress: '',
      ownerAddress: '',
      tradeTokenAddress: process.env.VUE_APP_TRADE_TOKEN_ADDRESS,
      farmAddress: process.env.VUE_APP_FARM_CONTRACT,
      tradeValue: '',
      tokensForSale: '',
      startDate: '',
      endDate: '',
      endDatePicker: '',
      startDatePicker: '',
      userTax: '5',
      sellerTax: '5',
      hasWhiteListing: true,
      rules: {
        date: () => parseInt(this.startDate) <= parseInt(this.endDate) || 'End date must be after start date',
      },
    }
  },
  methods: {
    getData() {
      const checkForm2 = this.$refs.form.validate()
      if (!checkForm2) return null
      const payload = {
        tokenAddress: this.tokenAddress,
        ownerAddress: this.ownerAddress,
        farmAddress: this.farmAddress,
        tradeTokenAddress: this.tradeTokenAddress,
        tradeValue: this.tradeValue,
        tokensForSale: this.tokensForSale,
        startDate: this.startDate,
        endDate: this.endDate,
        hasWhiteListing: this.hasWhiteListing,
        userTax: this.userTax,
        sellerTax: this.sellerTax,
      }
      return payload
    },
    reset() {
      this.$refs.form && this.$refs.form.resetValidation()
      this.hasWhiteListing = true
      this.farmAddress = process.env.VUE_APP_FARM_CONTRACT
      this.tradeTokenAddress = process.env.VUE_APP_TRADE_TOKEN_ADDRESS
      this.userTax = '5'
      this.sellerTax = '5'
      if (this.fixedPool) {
        this.tokenAddress = this.fixedPool.tokenAddress
        this.ownerAddress = this.fixedPool.ownerAddress
        this.tradeTokenAddress = this.fixedPool.tradeTokenAddress
        this.tradeValue = `${this.fixedPool.ratio}`
        this.tokensForSale = this.fixedPool.totalRaise
        this.startDate = `${new Date(this.fixedPool.startDate).getTime() / 1000}`
        this.endDate = `${new Date(this.fixedPool.endDate).getTime() / 1000}`
        this.userTax = this.fixedPool.userTax
        this.sellerTax = this.fixedPool.sellerTax
        this.endDate = `${new Date(this.fixedPool.endDate).getTime() / 1000}`
      }
    },
  },
  created() {
    this.reset()
  },
  filters: {
    timestampToLocalTime(time) {
      return moment(time * 1000)
        .local()
        .format('YYYY-MM-DD HH:mm')
    },
    timestampToGMTTime(time) {
      return moment(time * 1000)
        .utc()
        .format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>
