<template>
  <div class="d-flex">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn small v-bind="attrs" v-on="on" icon>
          <v-icon color="primary">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          @click="deployFixedPoolState = !deployFixedPoolState"
          :disabled="!item.isCorrectChainId || !walletConnected"
        >
          <v-list-item-title style="cursor: pointer">Deploy</v-list-item-title>
        </v-list-item>
        <v-list-item @click="updateContract" :disabled="!item.contract || !getConnectedState(item)">
          <v-list-item-title style="cursor: pointer">Update contract</v-list-item-title>
        </v-list-item>
        <v-list-item @click="addFixedPoolState = !addFixedPoolState">
          <v-list-item-title style="cursor: pointer">Duplicate</v-list-item-title>
        </v-list-item>
        <v-list-item @click="editFixedPoolState = !editFixedPoolState">
          <v-list-item-title style="cursor: pointer">Edit</v-list-item-title>
        </v-list-item>
        <v-list-item @click="deleteFixedPoolState = !deleteFixedPoolState">
          <v-list-item-title style="cursor: pointer">Delete</v-list-item-title>
        </v-list-item>
        <v-list-item @click="manageProjectWhitelist(item)">
          <v-list-item-title style="cursor: pointer">Manage Whitelist</v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="item.isCorrectChainId || !walletConnected"
          :class="!item.isCorrectChainId && walletConnected ? 'primary--text' : ''"
          @click="switchNetwork(+item.chainId)"
        >
          <v-list-item-title style="cursor: pointer">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">Switch network</span>
              </template>
              <span>Switch to {{ networkName }}</span>
            </v-tooltip></v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
    <FixedPoolEditDialog :state="editFixedPoolState" :fixedPool="item" />
    <FixedPoolAddDialog :state="addFixedPoolState" :fixedPool="item" />
    <FixedPoolDeleteDialog :state="deleteFixedPoolState" :fixedPool="item" />
    <FixedPoolDeployDialog :state="deployFixedPoolState" :fixedPool="item" />
    <ContractUpdateDialog :state="updateContractState" :fixedPool="item" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FixedPoolEditDialog from '@/modules/fixedPools/dialogs/FixedPoolEditDialog'
import FixedPoolAddDialog from '@/modules/fixedPools/dialogs/FixedPoolAddDialog'
import FixedPoolDeleteDialog from '@/modules/fixedPools/dialogs/FixedPoolDeleteDialog'
import FixedPoolDeployDialog from '@/modules/fixedPools/dialogs/FixedPoolDeployDialog'
import ContractUpdateDialog from '@/modules/fixedPools/dialogs/ContractUpdateDialog'
import { blockchainHandler } from '@/helpers/blockchainHandler.js'
export default {
  props: {
    item: Object,
  },
  components: {
    FixedPoolEditDialog,
    FixedPoolAddDialog,
    FixedPoolDeleteDialog,
    FixedPoolDeployDialog,
    ContractUpdateDialog,
  },
  data() {
    return {
      editFixedPoolState: false,
      addFixedPoolState: false,
      deleteFixedPoolState: false,
      deployFixedPoolState: false,
      updateContractState: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['walletConnected']),
    ...mapState('auth', ['account']),
    networkName: function () {
      const { name } = blockchainHandler.getChainConfig(this.item.chainId)
      return name
    },
  },
  methods: {
    ...mapActions('fixedPools', ['fetchContractInfo']),
    ...mapActions('auth', ['switchNetwork']),
    async updateContract() {
      this.$loading.active = true
      await this.fetchContractInfo(this.item.contract)
      this.updateContractState = !this.updateContractState
      this.$loading.active = false
    },
    getConnectedState(item) {
      return item.contractOwner && item.contractOwner.toLowerCase() === this.account && item.isCorrectChainId
    },
    manageProjectWhitelist(item) {
      this.$router.push(`fixedPools/${item.id}/whitelist`)
    },
  },
  created() {},
}
</script>
