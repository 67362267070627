<template>
  <v-form ref="form">
    <v-row>
      <v-col class="py-0" cols="6">
        <v-text-field
          v-model="participants"
          :rules="[$rules.positiveInterger]"
          label="Participants"
          dense
          outlined
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          v-model="tokensAllocated"
          dense
          label="TokensAllocated"
          :rules="[$rules.positiveNumber]"
          outlined
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          v-model="minAllocationUsd"
          :rules="[$rules.positiveNumber]"
          dense
          label="MinAllocationUsd"
          outlined
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          v-model="maxAllocationUsd"
          :rules="[$rules.positiveNumber]"
          dense
          label="MaxAllocationUsd"
          outlined
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          v-model="totalRaiseUsd"
          :rules="[$rules.positiveNumber]"
          dense
          label="TotalRaiseUsd"
          outlined
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field
          v-model="coverUrl"
          dense
          label="CoverUrl"
          outlined
          :hint="coverUrl ? `link to: ${$baseUrl}${coverUrl}` : ''"
          persistent-hint
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field v-model="shortDescription" dense label="ShortDescription" outlined></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field v-model="medium" dense label="Medium" outlined></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field v-model="telegram" dense label="Telegram" outlined></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field v-model="twitter" dense label="Twitter" outlined></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field v-model="web" dense label="Web" outlined></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field v-model="whitelistUrl" dense label="WhitelistUrl" outlined></v-text-field>
      </v-col>
      <v-col class="py-0" cols="6">
        <v-text-field v-model="forceProgressPercent" dense label="ForceProgressPercent" outlined></v-text-field>
      </v-col>
      <v-col class="py-0 d-flex" cols="12">
        <v-text-field label="ClaimType" class="col-md-8" v-model="claimType" dense outlined></v-text-field>
        <div class="d-flex ml-8 pt-3">
          <input type="radio" value="" v-model="claimType" />
          <span>Custom</span>

          <input type="radio" value="airdrop" v-model="claimType" style="margin-left: 10px !important" />
          <span>airdrop</span>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
export default {
  props: {
    fixedPool: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      participants: '',
      tokensAllocated: '',
      minAllocationUsd: '',
      maxAllocationUsd: '',
      totalRaiseUsd: '',
      shortDescription: '',
      coverUrl: '',
      medium: '',
      telegram: '',
      twitter: '',
      web: '',
      whitelistUrl: '',
      forceProgressPercent: '',
      claimType: '',
    }
  },
  methods: {
    getData() {
      if (!this.$refs.form.validate()) return null
      const data = {
        participants: this.participants,
        tokensAllocated: this.tokensAllocated,
        minAllocationUsd: this.minAllocationUsd,
        maxAllocationUsd: this.maxAllocationUsd,
        totalRaiseUsd: this.totalRaiseUsd,
        shortDescription: this.shortDescription,
        coverUrl: this.coverUrl ? this.$baseUrl + this.coverUrl : '',
        medium: this.medium,
        telegram: this.telegram,
        twitter: this.twitter,
        web: this.web,
        whitelistUrl: this.whitelistUrl,
        forceProgressPercent: this.forceProgressPercent,
        claimType: this.claimType,
      }
      return data
    },
    reset() {
      this.$refs.form && this.$refs.form.resetValidation()
      if (!this.fixedPool) {
        this.resetDefault()
      } else {
        this.participants = this.fixedPool.data.participants
        this.tokensAllocated = this.fixedPool.data.tokensAllocated
        this.minAllocationUsd = this.fixedPool.data.minAllocationUsd
        this.maxAllocationUsd = this.fixedPool.data.maxAllocationUsd
        this.totalRaiseUsd = this.fixedPool.data.totalRaiseUsd
        this.shortDescription = this.fixedPool.data.shortDescription
        this.medium = this.fixedPool.data.medium
        this.telegram = this.fixedPool.data.telegram
        this.twitter = this.fixedPool.data.twitter
        this.web = this.fixedPool.data.web
        this.whitelistUrl = this.fixedPool.data.whitelistUrl
        this.forceProgressPercent = this.fixedPool.data.forceProgressPercent
        this.claimType = `${this.fixedPool.data.claimType}`
        if (this.fixedPool.data.coverUrl && this.fixedPool.data.coverUrl.indexOf(this.$baseUrl) === 0) {
          this.coverUrl = this.fixedPool.data.coverUrl.substring(this.$baseUrl.length)
        }
      }
    },
    resetDefault() {
      this.participants = ''
      this.tokensAllocated = ''
      this.minAllocationUsd = ''
      this.maxAllocationUsd = ''
      this.totalRaiseUsd = ''
      this.shortDescription = ''
      this.coverUrl = ''
      this.medium = ''
      this.telegram = ''
      this.twitter = ''
      this.web = ''
      this.whitelistUrl = ''
      this.forceProgressPercent = ''
      this.claimType = ''
    },
  },
  created() {
    this.reset()
  },
}
</script>
