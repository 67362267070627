import SignIn from '@/views/auth/SignIn'
import GuestLayout from '@/components/layouts/GuestLayout'
import MainLayout from '@/components/layouts/MainLayout'
import FixedPools from '@/views/FixedPools'
import ProjectWhitelist from '@/views/ProjectWhitelist'

const routes = [
  {
    path: '/',
    redirect: '/fixedPools',
  },
  {
    path: '/',
    component: GuestLayout,
    children: [
      {
        path: 'signIn',
        name: 'signIn',
        component: SignIn,
        meta: {
          title: 'Sign In',
          auth: false,
        },
      },
    ],
  },
  {
    path: '/',
    name: 'mainLayout',
    component: MainLayout,
    children: [
      {
        path: 'fixedPools',
        name: 'fixedPools',
        component: FixedPools,
        meta: {
          title: 'Projects',
          auth: true,
        },
      },
      {
        path: 'fixedPools/:id/whitelist',
        name: 'fixedPools',
        component: ProjectWhitelist,
        meta: {
          title: 'Whitelist',
          auth: true,
        },
      },
    ],
  },
]

export default routes
